import useAuth from "@Hooks/use-auth";
import { AccessToken } from "@Utils/enums";
import ROUTE from "@Utils/routes";
import React from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/logo.png";
import styles from "./index.module.scss";
function Header() {
  const [isVerifying, user, isLoggedIn] = useAuth();
  const navbarClassName = "navbar navbar-light bg-light fixed-top " + styles.kensaNavbar;
  const history = useHistory();
  const onLogout = () => {
    localStorage.removeItem(AccessToken.TOKEN_KEY);
    window.location.href = window.location.origin + ROUTE.TECHNICIAN_LOGIN;
  }

  return (
    <nav className={navbarClassName}>
      <div>
        <a className="navbar-brand" href="https://kiemtraxecu.com" target="_blank" rel="noreferrer">
          <img src={Logo} className={styles.kensaLogo} alt="Kensa Logo" />
        </a>
        {user && <p className="d-inline-block mb-0">
          <strong>{user.first_name + ' ' + user.last_name}</strong>, <a href="javascript: void(0)" onClick={onLogout}>Logout</a>
        </p>}
      </div>
      <a className={styles.kensaHotline} href="tel:0901817129">
        <h4 className={styles.kensaHotline_text}>Hotline hỗ trợ</h4>
        <h3 className={styles.kensaHotline_phone}>0901.817.129</h3>
      </a>
    </nav>
  );
}

export default Header;
