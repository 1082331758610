import { Link, withRouter } from "react-router-dom";
import bodyStyles from "@Layouts/Body/index.module.scss";
import styles from './index.module.scss';
import React from "react";
import ROUTE from "src/utils/routes";

function TechnicianHomePage() {
  return (
    <>
      <div className="col-12 p-0">
        <h1 className={bodyStyles.kensaBody_Title}>Trang chủ cho Kỹ Thuật Viên</h1>
        <div className={bodyStyles.kensaBody_Full}>
          <div className={styles.kensaOrder_Content}>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-4">
                <Link className={`${styles.kensaOrder_Card} ${styles.kensaOrder_Card_Expertise}`} to={ROUTE.TECHNICIAN_EXPERTISE}>
                  <span className={styles.kensaOrder_Card_Text}>Thẩm Định Xe</span>
                </Link>
              </div>
              <div className="col-12 col-lg-4">
                <Link className={`${styles.kensaOrder_Card} ${styles.kensaOrder_Card_FindBike}`} to={ROUTE.TECHNICIAN_FIND_BIKE}>
                  <span className={styles.kensaOrder_Card_Text}>Tìm Xe</span>
                </Link>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(TechnicianHomePage);
