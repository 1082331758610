function isEmpty(value) {
    if (value instanceof Array) {
        return value === null || value === undefined || value.length === 0;
    } else if (value instanceof Object) {
        return (
            value === null || value === undefined || Object.keys(value).length === 0
        );
    } else {
        return value === "" || value === null || value === undefined;
    }
}

function isImage(file) {
    // Check file type is image/* or not
    if (file.type.match("image/*")) {
        return true;
    }
    return false;
}

function checkFieldPattern(errorFieldName, value, errorMsg) {
    const param = {};
    let pattern = null;
    param[errorFieldName] = "";
    if (errorFieldName === "errorCustomerPhone") {
        pattern = /(84|0[3|5|7|8|9])+([0-9]{8})/;
    }
    if (!(pattern).test(value)) {
        param[errorFieldName] = errorMsg;
    }
    this.setState(param);
    return pattern.test(value);
}

function checkFieldValid(errorFieldName, value, errorMsg) {
    const param = {};
    param[errorFieldName] = "";
    if (isEmpty(value)) {
        param[errorFieldName] = errorMsg;
    }

    this.setState(param);
    return !isEmpty(value);
}

export { checkFieldValid, checkFieldPattern, isEmpty, isImage };