import { Component } from "react";
import { PaymentStatus } from "../../utils/enums";

export default class OrderCheckOriginDetail extends Component {
  render() {
    const {
      code,
      serviceName,
      name,
      servicePrice,
      remainingFee,
      prepay,
      note,
      statusDesc,
      licensePlates,
      customerPhone,
      customerName,
      coupon,
      paymentStatus,
    } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Tên khách hàng
              </label>
              <label className="col-form-label col-12 col-md-6">
                {customerName}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Số điện thoại
              </label>
              <label className="col-form-label col-12 col-md-6">
                {customerPhone}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Mã đơn hàng:
              </label>
              <label className="col-form-label col-12 col-md-6">{code}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Đơn hàng:
              </label>
              <label className="col-form-label col-12 col-md-6">{name}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Dịch vụ:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {serviceName}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Biển số cần kiểm tra:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {licensePlates}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Phí dịch vụ:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {servicePrice.toLocaleString()} VNĐ
              </label>
            </div>
          </div>
          {paymentStatus === PaymentStatus.PAID && (
            <>
              <div className="col-12 col-md-6">
                <div className="row form-group">
                  <label className="col-form-label col-12 col-md-6 font-weight-bold">
                    Đã đặt cọc:
                  </label>
                  <label className="col-form-label col-12 col-md-6">
                    {prepay.toLocaleString()} VNĐ
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row form-group">
                  <label className="col-form-label col-12 col-md-6 font-weight-bold">
                    Phí còn lại:
                  </label>
                  <label className="col-form-label col-12 col-md-6">
                    {remainingFee.toLocaleString()} VNĐ
                  </label>
                </div>
              </div>
            </>
          )}
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Mã giới thiệu:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {coupon ? coupon : "Không"}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Ghi chú:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {note ? note : "Không"}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Trạng thái
              </label>
              <label
                className="col-form-label col-12 col-md-6"
                dangerouslySetInnerHTML={{ __html: statusDesc }}
              ></label>
            </div>
          </div>
        </div>
      </>
    );
  }
}
