import { OrderStatus } from "@Utils/enums";
import { Link } from "react-router-dom";

function OrderExpertiseAction({ order }) {
  return (
    <>
      <div className="text-center">
        {order.status == OrderStatus.IN_PROGRESS && (
          <a href={order.confirmUrl} className="btn btn-success mb-2 mb-md-0 mx-2 " style={{ minWidth: "150px" }} target="_blank">
            Báo cáo hoàn thành
          </a>
        )}
        {order.status == OrderStatus.CONFIRMED && (
          <>
            <a className="btn btn-primary mb-2 mb-md-0 mx-2 " style={{ minWidth: "150px" }} target="_blank" href={order.acceptUrl}>
              Chấp nhận đơn hàng
            </a>
            <a className="btn btn-danger mb-2 mb-md-0 mx-2 " style={{ minWidth: "150px" }} target="_blank" href={order.rejectUrl}>
              Từ chối đơn hàng
            </a>
          </>
        )}
      </div>
      <div className="text-center mt-3">
        <Link to={"/technician/expertise"}>Quay về trang danh sách</Link>
      </div>
    </>
  );
}
export default OrderExpertiseAction;
