import { Component, createRef } from "react";
import { withRouter } from "react-router";
import LoadingIcon from "../LoadingIcon";
import axios from "../../utils/axios";
import $ from "jquery";
import styles from "./index.module.scss";

class OrderFindBikeConfirm extends Component {
  constructor(props) {
    super(props);
    this.modal = createRef();
    this.submitOrder = this.props.submitOrder;
    this.modalClose = this.props.modalClose;
    this.state = {
      loading: false,
      dataServicePrices: [],
      dataVehicleManufactures: [],
      dataVehicleTypes: []
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    $(this.modal.current).modal("show");
    $(this.modal.current).on("hidden.bs.modal", () => {
      $(".modal-backdrop").remove();
      this.modalClose();
    });
    Promise.all([
      this.getVehicleManufactures(),
      this.getVehicleTypes(),
      this.getServicePrices(),
    ]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }
  onConfirm() {
    $(this.modal.current).modal("hide");
    this.submitOrder();
  }
  getVehicleTypes() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/types")
        .then((res) => {
          this.setState({
            dataVehicleTypes: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getVehicleManufactures() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/manufactures")
        .then((res) => {
          this.setState({
            dataVehicleManufactures: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }
  getServicePrices() {
    return new Promise((resovle, reject) => {
      axios
        .get("/services/prices", {
          params: {
            service_code: "TIM_XE",
          },
        })
        .then((res) => {
          this.setState({
            dataServicePrices: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          this.setState({
            loadingPrice: false,
          });
          resovle(true);
        });
    });
  }
  vehicleType() {
    const { vehicleType } = this.props.order;
    const { dataVehicleTypes } = this.state;
    if (dataVehicleTypes.filter((vt) => vt.id === vehicleType).length > 0) {
      return dataVehicleTypes.filter((vt) => vt.id === vehicleType)[0].name;
    }
    return 0;
  }
  vehicleManufacture() {
    const { vehicleManufacture } = this.props.order;
    const { dataVehicleManufactures } = this.state;
    if (
      dataVehicleManufactures.filter((vm) => vm.id === vehicleManufacture)
        .length > 0
    ) {
      return dataVehicleManufactures.filter(
        (vm) => vm.id === vehicleManufacture
      )[0].name;
    }
    return 0;
  }
  servicePrice() {
    const { servicePrice } = this.props.order;
    const { dataServicePrices } = this.state;
    if (dataServicePrices.filter((sp) => sp.id === servicePrice).length > 0) {
      return dataServicePrices.filter((sp) => sp.id === servicePrice)[0].price;
    }
    return 0;
  }
  discountFee() {
    const serviceFee = this.servicePriceValue(this.props.order.servicePrice);
    const { discountType, discountValue } = this.props.order;
    if (discountType === 1) {
      return serviceFee * (discountValue / 100);
    } else {
      return discountValue;
    }
  }
  render() {
    const { loading } = this.state;
    const {
      note,
      vehicleColors,
      vehicleVersion,
      vehicleName,
      budgetStart,
      budgetEnd,
      licensePlateArea,
      customerName,
      customerPhone,
      specialRequirement,
    } = this.props.order;
    return (
      <div
        className={`modal fade ${styles.kensaConfirm_Modal}`}
        id="confirmModal"
        ref={this.modal}
      >
        <div className={`modal-dialog ${styles.kensaConfirm_ModalDialog}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Xác nhận đơn hàng</h3>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              {loading ? (
                <LoadingIcon />
              ) : (
                <>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Tên khách hàng
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {customerName}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Số điện thoại
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {customerPhone}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Dịch vụ:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          Tìm xe
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Phí dịch vụ:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {this.servicePrice().toLocaleString()} VNĐ
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Hãng xe:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {this.vehicleManufacture()}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Phân loại xe:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {this.vehicleType()}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Tên xe
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {vehicleName}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Ngân sách
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {parseInt(budgetStart).toLocaleString()} - 
                          {parseInt(budgetEnd).toLocaleString()} VNĐ
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Biển số
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {licensePlateArea}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Phiên bản
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {vehicleVersion}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Màu xe
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {vehicleColors.join(", ")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Yêu cầu đặc biệt
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {specialRequirement}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Lưu ý
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {note}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-primary text-uppercase"
                  disabled={loading}
                  onClick={this.onConfirm.bind(this)}
                >
                  Xác nhận đăng ký dịch vụ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OrderFindBikeConfirm);
