import { Component } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import LoadingIcon from "@Components/LoadingIcon";
import OrderFormAction from "@Components/OrderFormAction";
import axios from "../../../../utils/axios";
import ROUTE from "../../../../utils/routes";
import { checkFieldValid } from "../../../../utils/validate";

class FindBikeVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      dataVehicleTypes: [],
      dataVehicleManufactures: [],
      dataServicePrices: [],
      loadingPrice: false,
      errorVehicleManufacture: "",
      errorVehicleType: "",
      errorVehicleName: "",
      errorServicePrice: "",
    };
    this.updateOrder = this.props.updateOrder;
    this.checkFieldValid = checkFieldValid.bind(this);
  }

  componentDidMount() {
    Promise.all([
      this.getVehicleTypes(),
      this.getVehicleManufactures(),
      this.getServicePrices(),
    ]).then(() => {
      this.setState({
        loadingData: false,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      const {
        errorVehicleManufacture,
        errorVehicleType,
        errorServicePrice,
        errorVehicleName,
      } = this.state;
      if (
        errorVehicleManufacture ||
        errorServicePrice ||
        errorVehicleName ||
        errorVehicleType
      ) {
        this.checkFormValid();
      }
    }
  }

  getVehicleTypes() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/types")
        .then((res) => {
          this.setState({
            dataVehicleTypes: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getVehicleManufactures() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/manufactures")
        .then((res) => {
          this.setState({
            dataVehicleManufactures: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getServicePrices() {
    return new Promise((resovle, reject) => {
      axios
        .get("/services/prices", {
          params: {
            service_code: "TIM_XE",
          },
        })
        .then((res) => {
          this.setState({
            dataServicePrices: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  onSelect(e, name) {
    this.updateOrder({
      [`${name}`]: e.value,
    });
  }

  onTextChange(e) {
    this.updateOrder({
      [`${e.target.name}`]: e.target.value,
    });
  }
  checkFormValid() {
    const {
      vehicleManufacture,
      vehicleType,
      vehicleName,
      servicePrice,
    } = this.props.order;
    let hasError = false;
    if (
      !this.checkFieldValid(
        "errorVehicleManufacture",
        vehicleManufacture,
        "Mời chọn hãng xe"
      )
    )
      hasError = true;
    if (
      !this.checkFieldValid(
        "errorVehicleType",
        vehicleType,
        "Mời chọn phân loại xe"
      )
    )
      hasError = true;
    if (
      !this.checkFieldValid("errorVehicleName", vehicleName, "Mời nhập tên xe")
    )
      hasError = true;
    if (
      !this.checkFieldValid(
        "errorServicePrice",
        servicePrice,
        "Mời chọn số lượng xe cần xem"
      )
    )
      hasError = true;
    return !hasError;
  }
  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.checkFormValid()) {
      return false;
    }
    this.props.history.push(ROUTE.ORDER_FIND_BIKE_REQUIREMENT);
    return false;
  }

  render() {
    const {
      vehicleManufacture,
      vehicleName,
      vehicleType,
      servicePrice,
      error,
    } = this.props.order;

    const {
      loadingData,
      dataVehicleManufactures,
      dataVehicleTypes,
      dataServicePrices,
    } = this.state;
    let selectedVehicleManufacture =
      dataVehicleManufactures.filter((dv) => dv.id === vehicleManufacture)
        .length > 0
        ? dataVehicleManufactures.filter(
            (dv) => dv.id === vehicleManufacture
          )[0]
        : null;
    if (selectedVehicleManufacture) {
      selectedVehicleManufacture = {
        value: selectedVehicleManufacture.id,
        label: selectedVehicleManufacture.name,
      };
    }
    let selectedVehicleType =
      dataVehicleTypes.filter((dv) => dv.id === vehicleType).length > 0
        ? dataVehicleTypes.filter((dv) => dv.id === vehicleType)[0]
        : null;
    if (selectedVehicleType) {
      selectedVehicleType = {
        value: selectedVehicleType.id,
        label: selectedVehicleType.name,
      };
    }
    let selectedServicePrice =
      dataServicePrices.filter((dv) => dv.id === servicePrice).length > 0
        ? dataServicePrices.filter((dv) => dv.id === servicePrice)[0]
        : null;
    if (selectedServicePrice) {
      selectedServicePrice = {
        value: selectedServicePrice.id,
        label: selectedServicePrice.name,
      };
    }
    const {
      errorVehicleManufacture,
      errorVehicleType,
      errorVehicleName,
      errorServicePrice,
    } = this.state;
    return (
      <>
        {loadingData ? (
          <LoadingIcon />
        ) : (
          <>
            <form
              className={"kensaForm_Container"}
              onSubmit={(e) => this.onSubmit(e)}
            >
              <div className="kensaForm_Content">
                <h1 className={`kensaForm_Title`}>
                  Thông tin cơ bản xe cần tìm
                </h1>
                <div className="expertise-form">
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Hãng xe: 
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder="Chọn hãng xe..."
                        options={dataVehicleManufactures.map((v) => {
                          return { value: v.id, label: v.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={
                          errorVehicleManufacture ? "invalid-select" : ""
                        }
                        defaultValue={selectedVehicleManufacture}
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSelect(e, "vehicleManufacture")}
                      />
                      {errorVehicleManufacture && (
                        <div className="invalid-feedback">
                          {errorVehicleManufacture}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Phân loại xe:
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder="Chọn phân loại xe..."
                        options={dataVehicleTypes.map((v) => {
                          return { value: v.id, label: v.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={errorVehicleType ? "invalid-select" : ""}
                        defaultValue={selectedVehicleType}
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSelect(e, "vehicleType")}
                      />
                      {errorVehicleType && (
                        <div className="invalid-feedback">
                          {errorVehicleType}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Tên xe:
                    </label>
                    <div className="col-12 col-lg-8">
                      <input
                        className={`form-control ${
                          errorVehicleName ? "invalid" : ""
                        }`}
                        defaultValue={vehicleName}
                        name="vehicleName"
                        placeholder="Ví dụ: Xe SH Ý"
                        onChange={(e) => this.onTextChange(e)}
                      />
                      {errorVehicleName && (
                        <div className="invalid-feedback">
                          {errorVehicleName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Chọn gói dịch vụ:
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder={
                          vehicleType
                            ? "Chọn số lượng xe cần kiểm tra..."
                            : "Cần chọn loại xe trước"
                        }
                        options={dataServicePrices.map((v) => {
                          return { value: v.id, label: v.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={errorServicePrice ? "invalid-select" : ""}
                        value={selectedServicePrice}
                        defaultValue={selectedServicePrice}
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSelect(e, "servicePrice")}
                      />
                      {errorServicePrice && (
                        <div className="invalid-feedback">
                          {errorServicePrice}
                        </div>
                      )}
                    </div>
                  </div>
                  {error && (
                    <div className="row">
                      <div className="col-12 text-center text-danger">
                        {error}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <OrderFormAction prev={null}></OrderFormAction>
            </form>
          </>
        )}
      </>
    );
  }
}

export default withRouter(FindBikeVehicle);
