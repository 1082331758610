import Axios from "src/utils/axios";

const { useState, useEffect } = require("react");
const { AccessToken } = require("src/utils/enums");

function useAuth() {
  const [isVerifying, setIsVerifying] = useState(true);
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getProfile = () => {
    Axios.get("auth/me")
      .then((response) => {
        setUser(response.data.data);
        setIsLoggedIn(true);
      })
      .catch(() => {
        localStorage.removeItem(AccessToken.TOKEN_KEY);
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem(AccessToken.TOKEN_KEY)) {
      getProfile();
    } else {
      setIsLoggedIn(false);
      setIsVerifying(false);
    }
  }, []);
  return [isVerifying, user, isLoggedIn, setIsVerifying];
}

export default useAuth;
