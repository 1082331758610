import { Component } from "react";
import LoadingIcon from "../LoadingIcon";
import styles from "./index.module.scss";
import axios from "../../utils/axios";
import KensaTooltip from "../KensaTooltip";
import KensaCoupon from "../KensaCoupon";
import { round } from "../../utils/functions";
class OrderFindBikePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      servicePrices: [],
      otherFee: 0,
    };
    this.updateOrder = this.props.updateOrder;
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    Promise.all([this.getServicePrices()]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  getServicePrices() {
    return new Promise((resolve, reject) => {
      axios
        .get("/services/prices", {
          params: { service_code: "TIM_XE", service_all: true },
        })
        .then((res) => {
          this.setState({
            servicePrices: res.data.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }

  servicePriceValue(servicePrice) {
    const { servicePrices } = this.state;
    if (servicePrices.length > 0) {
      const prices = servicePrices.filter((s) => {
        return s.id === servicePrice;
      });
      if (prices.length > 0) {
        return prices[0].price;
      }
    }
    return 0;
  }
  discountFee() {
    const serviceFee = this.servicePriceValue(this.props.order.servicePrice);
    const { discountType, discountValue } = this.props.order;
    if (discountType === 1) {
      return serviceFee * (discountValue / 100);
    } else {
      return discountValue;
    }
  }
  render() {
    const { loading, error, otherFee } = this.state;
    const { servicePrice } = this.props.order;
    const servicePriceValue = this.servicePriceValue(servicePrice);
    const discountFee = this.discountFee();
    const totalPrice = servicePriceValue + otherFee - discountFee;
    return (
      <>
        <div className={styles.kensaSummary}>
          <h3 className={styles.kensaSummary_Title}>Chi tiết giá</h3>
          <div className={styles.kensaSummary_Content}>
            {error ? (
              <>
                <p className="text-danger">{error}</p>
              </>
            ) : (
              <>
                {loading ? (
                  <LoadingIcon />
                ) : (
                  <>
                    <div className={styles.kensaSummary_Row}>
                      <div className={`${styles.kensaSummary_Col}`}>
                        <span className="font-weight-bold">Phí dịch vụ </span>
                        <KensaTooltip dataKey="phi_dich_vu" />
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span>{servicePriceValue.toLocaleString()} VNĐ</span>
                      </div>
                    </div>
                    <div className={styles.kensaSummary_Row}>
                      <div
                        className={`${styles.kensaSummary_Col} font-weight-bold`}
                      >
                        <span className="font-weight-bold">Phí khác</span>
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span>{otherFee.toLocaleString()} VNĐ</span>
                      </div>
                    </div>
                    <div className={styles.kensaSummary_Row}>
                      <KensaCoupon
                        updateOrder={this.updateOrder}
                        order={this.props.order}
                        serviceFee={servicePriceValue}
                        discountFee={discountFee}
                      />
                    </div>
                    <div
                      className={`${styles.kensaSummary_Row} ${styles.kensaSummary_TotalRow}`}
                    >
                      <div className={styles.kensaSummary_Col}>
                        <span
                          className={`${styles.kensaSummary_TotalLabel} font-weight-bold`}
                        >
                          Tổng chi phí
                        </span>
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span className={styles.kensaSummary_TotalValue}>
                          {totalPrice.toLocaleString()} VNĐ
                        </span>
                      </div>
                    </div>
                    {(this.props.showBox && !!totalPrice) && (
                      <>
                        <div className={styles.kensaSummary_Box}>
                          <div className={`${styles.kensaSummary_Row} pb-0`}>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold`}
                            >
                              <span>Tiền cọc</span>
                              <KensaTooltip dataKey="tien_coc" />
                            </div>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold`}
                            >
                              <span>
                                {round(
                                  totalPrice * (50 / 100)
                                ).toLocaleString()}
                                VNĐ
                              </span>
                            </div>
                          </div>
                          <div className={`${styles.kensaSummary_Row} pb-0`}>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold`}
                            >
                              <span>Thanh toán khi có kết quả</span>
                            </div>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold text-success`}
                            >
                              <span>
                                {(
                                  totalPrice - round(totalPrice * (50 / 100))
                                ).toLocaleString()}
                                VNĐ
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default OrderFindBikePrice;
