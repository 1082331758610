const OrderStatus = {
  CREATED: 1,
  CONFIRMED: 2,
  IN_PROGRESS: 3,
  COMPLETED: 4,
  CANCELED: 5,
};
const PaymentStatus = {
  PENDING: 1,
  PAID: 2,
  FAILED: 3,
};

const AccessToken = {
  TOKEN_KEY: "Kensa_Token",
};

const SellerType = {
  SHOP: 1,
  PERSONAL: 2,
};

const LicensePlateArea = {
  CITY: "Thành phố",
  PROVINCE: "Tỉnh",
};

export { OrderStatus, PaymentStatus, AccessToken, SellerType, LicensePlateArea };
