import { Component } from "react";
export default class LoadingIcon extends Component {
  render() {
    return (
      <div className="text-center mx-auto">
        <div className="loadingio-spinner-disk-b6avn49nelc">
          <div className="ldio-rtnxkhsntbk">
            <div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
