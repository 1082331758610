import { Component } from "react";
import { PaymentStatus } from "../../utils/enums";

export default class OrderExpertiseDetail extends Component {
  render() {
    const { code, serviceName, name, createdAt, createdBy, vehicleManufactureName, vehicleTypeName, servicePrice, prepay, remainingFee, appointmentTime, appointmentLocation, paymentStatus, areaDesc, areaSubFee, note, coupon, statusDesc, customerName, customerPhone, technicianName } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Mã đơn hàng:</label>
              <label className="col-form-label col-12 col-md-6">{code}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Đơn hàng:</label>
              <label className="col-form-label col-12 col-md-6">{name}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Tên khách hàng</label>
              <label className="col-form-label col-12 col-md-6">{customerName}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Số điện thoại</label>
              <label className="col-form-label col-12 col-md-6">
                <a href={`tel:${customerPhone}`}>{customerPhone}</a>
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Dịch vụ:</label>
              <label className="col-form-label col-12 col-md-6">{serviceName}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Phí dịch vụ:</label>
              <label className="col-form-label col-12 col-md-6">{servicePrice.toLocaleString()} VNĐ</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Phụ phí di chuyển:</label>
              <label className="col-form-label col-12 col-md-6">{areaSubFee.toLocaleString()} VNĐ</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Tổng phí:</label>
              <label className="col-form-label col-12 col-md-6">{(areaSubFee + servicePrice).toLocaleString()} VNĐ</label>
            </div>
          </div>
          {paymentStatus === PaymentStatus.PAID && (
            <>
              <div className="col-12 col-md-6">
                <div className="row form-group">
                  <label className="col-form-label col-12 col-md-6 font-weight-bold">Đã đặt cọc:</label>
                  <label className="col-form-label col-12 col-md-6">{prepay.toLocaleString()} VNĐ</label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row form-group">
                  <label className="col-form-label col-12 col-md-6 font-weight-bold">Phí còn lại:</label>
                  <label className="col-form-label col-12 col-md-6">{(areaSubFee + servicePrice - prepay).toLocaleString()} VNĐ</label>
                </div>
              </div>
            </>
          )}
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Hãng xe:</label>
              <label className="col-form-label col-12 col-md-6">{vehicleManufactureName}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Phân loại xe:</label>
              <label className="col-form-label col-12 col-md-6">{vehicleTypeName}</label>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Thời gian gặp mặt:</label>
              <label className="col-form-label col-12 col-md-6">{appointmentTime}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Địa điểm gặp mặt:</label>
              <label className="col-form-label col-12 col-md-6">{appointmentLocation}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Quận / Huyện:</label>
              <label className="col-form-label col-12 col-md-6">{areaDesc}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Kỹ thuật viên:</label>
              <label className="col-form-label col-12 col-md-6">{technicianName}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Mã giới thiệu:</label>
              <label className="col-form-label col-12 col-md-6">{coupon ? coupon : "Không"}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Ngày tạo đơn:</label>
              <label className="col-form-label col-12 col-md-6">{createdAt}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Người tạo:</label>
              <label className="col-form-label col-12 col-md-6">{createdBy}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Ghi chú:</label>
              <label className="col-form-label col-12 col-md-6">{note ? note : "Không"}</label>
            </div>
          </div>
          
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Trạng thái</label>
              <label className="col-form-label col-12 col-md-6" dangerouslySetInnerHTML={{ __html: statusDesc }}></label>
            </div>
          </div>
        </div>
      </>
    );
  }
}
