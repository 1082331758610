import { Component } from "react";
import LoadingIcon from "@Components/LoadingIcon";
import bodyStyles from "@Layouts/Body/index.module.scss";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import axios from "../../utils/axios";
import ROUTE from "../../utils/routes";
import OrderFindBikePrice from "@Components/OrderFindBikePrice";
import FindBikeCustomer from "./pages/FindBikeCustomer";
import FindBikeVehicle from "./pages/FindBikeVehicle";
import FindBikeRequirement from "./pages/FindBikeRequirement";
import OrderFindBikeConfirm from "@Components/OrderFindBikeConfirm";
class FindBikePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openingConfirm: false,
      vehicleName : "",
      vehicleManufacture: null,
      vehicleType: null,  
      servicePrice: null,
      budgetStart: 0,
      budgetEnd: 0,
      vehicleVersion : "",
      vehicleColors: [],
      licensePlateArea: "",
      specialRequirement: "",
      note: "",
      customerName: "",
      customerPhone: "",
      coupon: "",
      discountType: null,
      discountValue: 0,
      error: "",
    };
  }

  updateOrder(data) {
    this.setState(data);
  }
  confirmOrder() {
    this.setState({
      openingConfirm: true
    });
  }
  submitOrder() {
    this.setState({
      loading: true,
    });
    const {
      vehicleManufacture,
      vehicleType,
      vehicleName,
      servicePrice,
      vehicleVersion,
      vehicleColors,
      budgetStart,
      budgetEnd,
      specialRequirement,
      licensePlateArea,
      note,
      customerName,
      customerPhone,
      coupon
    } = this.state;
    axios
      .post("/orders/find-bike", {
        vehicle_manufacture_id: vehicleManufacture,
        vehicle_type_id: vehicleType,
        vehicle_name: vehicleName,
        service_price_id: servicePrice,
        start_budget: budgetStart,
        end_budget: budgetEnd,
        color: vehicleColors.join(', '),
        license_plate_area: licensePlateArea,
        vehicle_version: vehicleVersion,
        requirement: specialRequirement,
        customer_name: customerName,
        customer_phone: customerPhone,
        coupon: coupon.toUpperCase(),
        note: note,
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/order/" + res.data.data.code);
      })
      .catch((err) => {
        this.setState({
          error: err.response ? err.response.data.message : err.message,
          loading: false,
        });
      });
  }

  render() {
    const { loading, openingConfirm } = this.state;
    return (
      <>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="col-12 p-0">
              <h1 className={bodyStyles.kensaBody_Title}>Đăng ký Dịch Vụ Tìm Xe</h1>
            </div>
            <BrowserRouter>
              <div className={bodyStyles.kensaBody_Left}>
                <Switch>
                  <Redirect
                    exact
                    from={ROUTE.ORDER_FIND_BIKE}
                    to={ROUTE.ORDER_FIND_BIKE_VEHICLE}
                  />
                  <Route
                    path={ROUTE.ORDER_FIND_BIKE_VEHICLE}
                    render={() => (
                      <FindBikeVehicle
                        order={this.state}
                        updateOrder={this.updateOrder.bind(this)}
                      />
                    )}
                  />
                  <Route
                    path={ROUTE.ORDER_FIND_BIKE_REQUIREMENT}
                    render={() => (
                      <FindBikeRequirement
                        order={this.state}
                        updateOrder={this.updateOrder.bind(this)}
                      />
                    )}
                  />
                  <Route
                    path={ROUTE.ORDER_FIND_BIKE_CUSTOMER}
                    render={() => (
                      <FindBikeCustomer
                        order={this.state}
                        updateOrder={this.updateOrder.bind(this)}
                        confirmOrder={this.confirmOrder.bind(this)}
                      />
                    )}
                  />
                </Switch>
              </div>
              <div className={bodyStyles.kensaBody_Right}>
                <OrderFindBikePrice showBox={true} order={this.state} updateOrder={this.updateOrder.bind(this)} />
              </div>
            </BrowserRouter>
          </>
        )}
        {openingConfirm && (
          <>
            <OrderFindBikeConfirm
              order={this.state}
              submitOrder={this.submitOrder.bind(this)}
              modalClose={(() => {
                this.setState({ openingConfirm: false });
              })}
            />
          </>
        )}
      </>
    );
  }
}
export default withRouter(FindBikePage);
