import { Component } from "react";
import { withRouter } from "react-router";
import styles from './index.module.scss';
class Footer extends Component{
    render() {
        return (
            <div className={`${styles.kensaFooter} ${this.props.location.pathname.indexOf('/expertise') > -1 ? styles.kensaFooter_Top : '' }`}>
                <div className={styles.kensaCopyright}>
                    Copyright by Kensa, 2021
                </div>
                <div className={styles.kensaPrivacy}>
                    <a href="https://kiemtraxecu.com/dieu-khoan-su-dung">Điều khoản sử dụng</a>
                    <a href="https://kiemtraxecu.com/chinh-sach-bao-mat">Chính sách bảo mật</a>
                </div>
            </div>
        )
    }
}

export default withRouter(Footer);