class ROUTE {
    static HOME                         = '/';
    static ORDER                        = '/order';
    static ORDER_EXPERTISE              = '/new';
    static ORDER_EXPERTISE_VEHICLE      = '/new/vehicle';
    static ORDER_EXPERTISE_APPOINTMENT  = '/new/appointment';
    static ORDER_EXPERTISE_CUSTOMER     = '/new/customer';
    static ORDER_CHECK_ORIGIN           = '/check-goc';
    static ORDER_CHECK_ORIGIN_VEHICLE   = '/check-goc/xe';
    static ORDER_CHECK_ORIGIN_CUSTOMER  = '/check-goc/khach-hang';
    static ORDER_FIND_BIKE              = '/find-bike';
    static ORDER_FIND_BIKE_VEHICLE      = '/find-bike/vehicle';
    static ORDER_FIND_BIKE_REQUIREMENT  = "/find-bike/requirement";
    static ORDER_FIND_BIKE_CUSTOMER     = '/find-bike/customer';
    static ORDER_PAYMENT_RETURN         = '/order/payment-return';
    static ORDER_DETAIL                 = '/order/:code';
    static ORDER_CONFIRM                = '/order/:code/confirm';
    static TECHNICIAN                   = '/technician';
    static TECHNICIAN_HOME              = '/technician/home';
    static TECHNICIAN_LOGIN             = '/technician/login';
    static TECHNICIAN_EXPERTISE         = '/technician/expertise';
    static TECHNICIAN_EXPERTISE_DETAIL  = '/technician/expertise/:code';
    static TECHNICIAN_FIND_BIKE         = '/technician/find-bike';
    static TECHNICIAN_FIND_BIKE_DETAIL  = '/technician/find-bike/:code';
}
export default ROUTE;