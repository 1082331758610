import { withRouter } from "react-router-dom";
import bodyStyles from "@Layouts/Body/index.module.scss";

import React from "react";
import TechnicianLoginForm from '@Components/TechnicianLoginForm';

function TechnicianLoginPage() {
  return (
    <>
      <div className="col-12 p-0">
        <h1 className={bodyStyles.kensaBody_Title}>Đăng nhập</h1>
        <div className={bodyStyles.kensaBody_Full}>
          <TechnicianLoginForm />
        </div>
      </div>
    </>
  );
}

export default withRouter(TechnicianLoginPage);
