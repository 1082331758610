import { Component } from "react";
import { withRouter } from "react-router";
import axios from "../../utils/axios";
import bodyStyles from "@Layouts/Body/index.module.scss";
import LoadingIcon from "@Components/LoadingIcon";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import ROUTE from "../../utils/routes";
import qs from "qs";
class PaymentReturnPage extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(this.props.location.search.replace("?", ""));
    let code = "";
    let isPayment = true;
    if (params.vnp_TxnRef) {
      code = params.vnp_TxnRef.split('_')[0];
    } else if (params.order_no) {
      code = params.order_no.split('_')[0];
    } else {
      code = params.order_code.split('_')[0];
      isPayment = false;
    }
    this.state = {
      loading: false,
      countTime: 5,
      error: "",
      code: code,
      isPayment: isPayment,
    };
    this.setInterval = null;
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    if (this.state.isPayment) {
      const search = this.props.location.search;
      axios
        .get(`/payments/return${search}`)
        .then((res) => {
          this.setState({ ...res.data.data });
          this.startCounting();
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    } else {
      axios
        .get(`/orders/${this.state.code}`)
        .then((res) => {
          this.setState({
            ...res.data.data,
            ...{
              message:
                `Cám ơn bạn đã xác nhận đặt cọc. Xin vui lòng đến địa chỉ công ty đặt cọc trước ${res.data.data.billingPeriod} để có thể tiếp tục đơn hàng.`,
            },
          });
          this.startCounting();
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  }
  componentWillUnmount() {
    clearInterval(this.setInterval);
  }
  startCounting() {
    this.setInterval = setInterval(() => {
      const currentTime = this.state.countTime;
      if (currentTime < 0) {
        this.props.history.push(`${ROUTE.ORDER}/${this.state.code}`);
      } else {
        this.setState({
          countTime: this.state.countTime - 1,
        });
      }
    }, 1000);
  }
  render() {
    const { loading, message, countTime, error, code } = this.state;
    return (
      <>
        <div className="col-12 p-0">
          <h1 className={bodyStyles.kensaBody_Title}>
            Kết quả đặt cọc: {code}
          </h1>
        </div>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className={bodyStyles.kensaBody_Full}>
              <div className={styles.kensaOrder_Detail}>
                {!error ? (
                  <>
                    <div className="text-center mb-3">
                      <p>{message}</p>
                    </div>
                    {countTime >= 0 && (
                      <>
                        <div className="text-center">
                          <p>
                            Trang sẽ tự động chuyển hướng về trang chi tiết đơn
                            hàng trong 00:0
                            {countTime} giây...
                          </p>
                        </div>
                      </>
                    )}
                    {countTime < 0 && (
                      <>
                        <div className="text-center">
                          <Link
                            className="btn btn-primary"
                            to={`${ROUTE.ORDER}/${code}`}
                          >
                            Quay về trang trước
                          </Link>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="text-center text-danger mb-3">
                    <p className="text-danger">{error}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(PaymentReturnPage);
