import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment/moment";
import { OrderStatus } from "@Utils/enums";
function OrderAction({ children }) {
  return (
    <>
      <div className={styles.kensaOrder_List_ItemAction}>{children}</div>
    </>
  );
}

function OrderInfo({ children }) {
  return (
    <>
      <div className={styles.kensaOrder_List_ItemContent}>{children}</div>
    </>
  );
}

function OrderItem({ children, order, url, index }) {
  let className = styles.kensaOrder_List_Item;
  if (index == 0) className += ` ${styles["kensaOrder_List_Item--first"]}`;
  if (index % 2 == 0) className += ` ${styles["kensaOrder_List_Item--even"]}`;
  if (index % 2 != 0) className += ` ${styles["kensaOrder_List_Item--odd"]}`;
  if (order.status == OrderStatus.CONFIRMED) className += ` ${styles["kensaOrder_List_Item--new"]}`;
  if (
    order.serviceType == "TIM_XE" && moment(order.deadline, "DD/MM hh:mm:ss").diff(moment(), "days") <= 3 || 
    order.serviceType == "THAM_DINH_XE" && moment(order.appointmentTime, "DD/MM/YYYY hh:mm:ss").diff(moment(), 'seconds') < 0
  ) className += ` ${styles["kensaOrder_List_Item--expire"]}`;

  return (
    <>
      <div className="col-12 col-md-6">
        <Link className={className} to={url}>
          {children}
        </Link>
      </div>
    </>
  );
}

function OrderList(props) {
  return (
    <>
      <div className={styles.kensaOrder_List}>
        <div className="row">{props.children}</div>
      </div>
    </>
  );
}

OrderList.Item = OrderItem;
OrderList.Action = OrderAction;
OrderList.Info = OrderInfo;

export default OrderList;
