import Checkbox from "rc-checkbox";
import NumberFormat from "react-number-format";
import bodyStyles from "@Layouts/Body/index.module.scss";
import styles from "../../pages/OrderConfirm/index.module.scss";
import axios from "../../utils/axios";
import LoadingIcon from "@Components/LoadingIcon";
import { Component } from "react";
import { Link } from "react-router-dom";

export default class OrderExpertiseComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      code: this.props.code,
      secret: this.props.secret,
      paymentMethod: null,
      purchaseStatus: null,
      commission: null,
      purchaseReason: "",
      createFindBikeOrder: null,
      createFindBikeOrderReason: "",
      errorCommission: "",
      errorPaymentMethod: "",
      errorPurchaseStatus: "",
      errorPurchaseReason: "",
      errorCreateFindBikeOrder: "",
      errorCreateFindBikeOrderReason: "",
    };
  }
  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      errorPaymentMethod: "",
      errorCommission: "",
      errorPurchaseStatus: "",
      errorPurchaseReason: "",
      errorCreateFindBikeOrder: "",
      errorCreateFindBikeOrderReason: "",
    });
    const { code, secret, purchaseStatus, commission, paymentMethod, purchaseReason, createFindBikeOrder, createFindBikeOrderReason } = this.state;
    let hasError = false;
    if (paymentMethod === null) {
      this.setState({
        errorPaymentMethod: "Mời chọn phương thức thanh toán của khách hàng",
      });
      hasError = true;
    }
    if (commission === null || commission === "") {
      this.setState({
        errorCommission: "Mời nhập phí bảo hành. Phí bảo hành tối thiểu là 0",
      });
      hasError = true;
    }
    if (purchaseStatus === null) {
      this.setState({
        errorPurchaseStatus: "Mời chọn khách có mua xe hay không",
      });
      hasError = true;
    }
    if (purchaseStatus === 0) {
      if (purchaseReason === "") {
        this.setState({
          errorPurchaseReason: "Mời nhập lý do khách không mua xe",
        });
        hasError = true;
      } else if (purchaseReason.trim().length < 30) {
        this.setState({
          errorPurchaseReason: "Mời nhập lý do khách không mua xe tối thiểu 30 ký tự",
        });
        hasError = true;
      }
    }

    if (purchaseStatus === 2 || purchaseStatus === 0) {
      if (createFindBikeOrder === null) {
        this.setState({
          errorCreateFindBikeOrder: "Mời chọn có chuyển sang tìm xe hay không",
        });
        hasError = true;
      } else if (createFindBikeOrder === 0) {
        if (createFindBikeOrderReason === "") {
          this.setState({
            errorCreateFindBikeOrderReason: "Mời nhập lý do không tạo đơn Tìm xe cho khách",
          });
          hasError = true;
        } else if (createFindBikeOrderReason.trim().length < 20) {
          this.setState({
            errorCreateFindBikeOrderReason: "Mời nhập lý do không tạo đơn tối thiểu 20 ký tự",
          });
          hasError = true;
        }
      }
    }

    if (hasError) {
      return false;
    }
    this.setState({
      loading: true,
    });
    axios
      .post("/orders/expertise/confirm", {
        code: code,
        confirm_token: secret,
        purchase_status: purchaseStatus,
        commission: commission,
        payment_method: paymentMethod,
        purchase_reason: purchaseReason,
        create_find_bike_order_status: createFindBikeOrder,
        create_find_bike_order_reason: createFindBikeOrderReason
      })
      .then((res) => {
        this.setState({
          success: true,
        });
      })
      .catch((err) => {
        alert(err.response ? err.response.data.message : err.message);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
    return false;
  }

  onPurchaseStatusChange(val) {
    this.setState({
      purchaseStatus: val,
      errorPurchaseStatus: "",
      errorPurchaseReason: "",
    });
  }

  onMethodChange(val) {
    this.setState({
      paymentMethod: val,
      errorPaymentMethod: "",
    });
  }

  onCommissionChange(val) {
    this.setState({
      commission: val !== "" ? parseInt(val.replaceAll(",", "")) : null,
      errorCommission: "",
    });
  }

  onReasonChange(val) {
    this.setState({
      purchaseReason: val,
      errorPurchaseReason: "",
    });
  }

  onCreateFindBikeOrderChange(val) {
    this.setState({
      createFindBikeOrder: val,
      errorCreateFindBikeOrder: "",
    });
  }

  onCreateFindBikeOrderReasonChange(val) {
    this.setState({
      createFindBikeOrderReason: val || "",
      errorCreateFindBikeOrderReason: ""
    });
  }

  render() {
    const order = this.props.order;
    const { success, loading, paymentMethod, purchaseStatus, purchaseReason, commission, createFindBikeOrder, createFindBikeOrderReason, errorCommission, errorPaymentMethod, errorPurchaseStatus, errorPurchaseReason, errorCreateFindBikeOrder, errorCreateFindBikeOrderReason } = this.state;
    return (
      <>
        {loading && <LoadingIcon />}
        {!loading && success && (
          <>
            <div className={bodyStyles.kensaBody_Full}>
              <div className={styles.kensaOrder_Detail}>
                <div className="text-center text-success font-weight-bold py-3">
                  <p className="text-success font-weight-bold mb-3">Cập nhật trạng thái cho đơn hàng thành công</p>
                  <Link to={"/technician/expertise"}>Quay về trang chủ</Link>
                </div>
              </div>
            </div>
          </>
        )}
        {!loading && !success && (
          <div className={bodyStyles.kensaBody_Full}>
            <form className={styles.kensaOrder_Confirm} onSubmit={this.onSubmit.bind(this)}>
              <div className="row form-group">
                <div className="col-12">
                  <h5 className="font-weight-bold mb-3">Thông tin đơn hàng</h5>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Thẩm định xe:</label>
                    <label className="col-12 col-md-8 col-form-label">
                      {order.vehicleManufactureName} {order.vehicleName}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Ngày hẹn:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.appointmentTime}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Quận / Huyện:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.areaDesc}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Địa điểm:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.appointmentLocation}</label>
                  </div>
                </div>
              </div>

              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Phương thức thanh toán phí còn lại?</label>
                <div className="col-md-8 d-flex align-items-center">
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={paymentMethod === 1} onChange={(e) => this.onMethodChange(1)} />
                    Tiền mặt
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={paymentMethod === 2} onChange={(e) => this.onMethodChange(2)} />
                    Chuyển khoản
                  </label>
                </div>
                {errorPaymentMethod && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorPaymentMethod}</div>}
              </div>
              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Phí bảo hành:</label>
                <div className="col-12 col-md-8">
                  <NumberFormat className="form-control" defaultValue={commission} thousandSeparator={true} onChange={(e) => this.onCommissionChange(e.target.value)} />
                </div>
                {errorCommission && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorCommission}</div>}
              </div>
              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Khách có mua xe hay không?</label>
                <div className="col-md-8 d-flex align-items-center">
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={purchaseStatus !== null && purchaseStatus === 1} onChange={(e) => this.onPurchaseStatusChange(1)} />
                    Đã mua
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={purchaseStatus !== null && purchaseStatus === 2} onChange={(e) => this.onPurchaseStatusChange(2)} />
                    Đang cân nhắc
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={purchaseStatus !== null && purchaseStatus === 0} onChange={(e) => this.onPurchaseStatusChange(0)} />
                    Không mua
                  </label>
                </div>
                {errorPurchaseStatus && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorPurchaseStatus}</div>}
              </div>
              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Lý do khách không mua xe:</label>
                <div className="col-12 col-md-8">
                  <textarea className="form-control" onChange={(e) => this.onReasonChange(e.target.value)} defaultValue={purchaseReason} placeholder="Hãy ghi chi tiết, để công ty chăm sóc khách hàng chu đáo"></textarea>
                </div>
                {errorPurchaseReason && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorPurchaseReason}</div>}
              </div>
              {purchaseStatus !== null && purchaseStatus !== 1 && (
                <>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Có chuyển sang tìm xe cho khách hay không?</label>
                    <div className="col-md-8 d-flex align-items-center">
                      <label className="col-4 col-form-label">
                        <Checkbox className="mr-2" checked={createFindBikeOrder !== null && createFindBikeOrder === 1} onChange={(e) => this.onCreateFindBikeOrderChange(1)} />
                        Có
                      </label>
                      <label className="col-4 col-form-label">
                        <Checkbox className="mr-2" checked={createFindBikeOrder !== null && createFindBikeOrder === 0} onChange={(e) => this.onCreateFindBikeOrderChange(0)} />
                        Không
                      </label>
                    </div>
                    {errorCreateFindBikeOrder && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorCreateFindBikeOrder}</div>}
                  </div>
                  {createFindBikeOrder !== null && createFindBikeOrder === 0 && (
                    <>
                      <div className="row form-group">
                        <label className="col-12 col-md-4 col-form-label font-weight-bold">Lý do không tạo đơn tìm xe:</label>
                        <div className="col-12 col-md-8">
                          <textarea className="form-control" onChange={(e) => this.onCreateFindBikeOrderReasonChange(e.target.value)} defaultValue={createFindBikeOrderReason} placeholder="Hãy ghi chi tiết lý do không chốt được đơn"></textarea>
                        </div>
                        {errorCreateFindBikeOrderReason && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorCreateFindBikeOrderReason}</div>}
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="row form-group">
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-success">
                    Xác nhận
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}
