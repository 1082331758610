import { Component } from "react";
import LoadingIcon from "../LoadingIcon";
import styles from "./index.module.scss";
import axios from "../../utils/axios";
import KensaTooltip from "../KensaTooltip";
import KensaCoupon from "../KensaCoupon";
import { round } from "../../utils/functions";
class OrderExpertisePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: false,
      servicePrices: [],
      areas: [],
      otherFee: 0,
    };
    this.updateOrder = this.props.updateOrder;
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    Promise.all([this.getServicePrices(), this.getAreas()]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }
  getServicePrices() {
    return new Promise((resolve, reject) => {
      axios
        .get("/services/prices", {
          params: { service_code: "THAM_DINH_XE", service_all: true },
        })
        .then((res) => {
          this.setState({
            servicePrices: res.data.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }
  getAreas() {
    return new Promise((resolve, reject) => {
      axios
        .get("/areas")
        .then((res) => {
          const parentAreas = res.data.data;
          const areas = [];
          parentAreas.forEach((pA) => {
            pA.children.forEach((cA) => {
              areas.push(cA);
            });
          });
          this.setState({
            areas: areas,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }
  servicePrice() {
    const { servicePrices } = this.state;
    if (servicePrices.length > 0) {
      const { servicePrice } = this.props.order;
      const matched = servicePrices.filter((s) => s.id === servicePrice);
      if (matched.length > 0) {
        return matched[0].price;
      }
    }
    return 0;
  }

  getAreaFee() {
    const dataAreas = this.state.areas;
    if (dataAreas.length > 0) {
      const { areas } = this.props.order;
      if (areas.length > 0) {
        const matched = dataAreas.filter((a) => areas.indexOf(a.id) > -1);
        if (matched.length > 0) {
          matched.sort((a, b) => {
            return b.subFee - a.subFee;
          });
          return matched[0].subFee;
        }
      }
    }
    return 0;
  }

  discountFee() {
    const serviceFee = this.servicePrice();
    const { discountType, discountValue } = this.props.order;
    if (discountType === 1) {
      return serviceFee * (discountValue / 100);
    } else {
      return discountValue;
    }
  }
  render() {
    const { error, loading, otherFee } = this.state;
    const serviceFee = this.servicePrice();
    const areaSubFee = this.getAreaFee();
    const discountFee = this.discountFee();
    return (
      <>
        <div className={styles.kensaSummary}>
          <h3 className={styles.kensaSummary_Title}>Chi tiết giá</h3>
          <div className={styles.kensaSummary_Content}>
            {error ? (
              <>
                <p className="text-danger">{error}</p>
              </>
            ) : (
              <>
                {loading ? (
                  <LoadingIcon />
                ) : (
                  <>
                    <div className={styles.kensaSummary_Row}>
                      <div className={`${styles.kensaSummary_Col}`}>
                        <span className="font-weight-bold">Phí dịch vụ </span>
                        <KensaTooltip dataKey="phi_dich_vu" />
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span>{serviceFee.toLocaleString()} VNĐ</span>
                      </div>
                    </div>
                    <div className={styles.kensaSummary_Row}>
                      <div
                        className={`${styles.kensaSummary_Col} font-weight-bold`}
                      >
                        <span className="font-weight-bold">
                          Phụ phí di chuyển{" "}
                          <KensaTooltip dataKey="phu_phi_di_chuyen" />
                        </span>
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span>{areaSubFee.toLocaleString()} VNĐ</span>
                      </div>
                    </div>
                    <div className={styles.kensaSummary_Row}>
                      <div
                        className={`${styles.kensaSummary_Col} font-weight-bold`}
                      >
                        <span className="font-weight-bold">Phí khác</span>
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span>{otherFee.toLocaleString()} VNĐ</span>
                      </div>
                    </div>
                    <div className={styles.kensaSummary_Row}>
                      <KensaCoupon
                        updateOrder={this.updateOrder}
                        order={this.props.order}
                        serviceFee={serviceFee}
                        discountFee={discountFee}
                      />
                    </div>
                    <div
                      className={`${styles.kensaSummary_Row} ${styles.kensaSummary_TotalRow}`}
                    >
                      <div className={styles.kensaSummary_Col}>
                        <span
                          className={`${styles.kensaSummary_TotalLabel} font-weight-bold`}
                        >
                          Tổng chi phí
                        </span>
                      </div>
                      <div className={styles.kensaSummary_Col}>
                        <span className={styles.kensaSummary_TotalValue}>
                          {(
                            serviceFee +
                            areaSubFee +
                            otherFee -
                            discountFee
                          ).toLocaleString()}{" "}
                          VNĐ
                        </span>
                      </div>
                    </div>
                    {(this.props.showBox && !!serviceFee) && (
                      <>
                        <div className={styles.kensaSummary_Box}>
                          <div className={`${styles.kensaSummary_Row} pb-0`}>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold`}
                            >
                              <span>Tiền cọc</span>{" "}
                              <KensaTooltip dataKey="tien_coc" />
                            </div>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold`}
                            >
                              <span>
                                {round(
                                  (serviceFee + otherFee - discountFee) *
                                    (50 / 100)
                                ).toLocaleString()}{" "}
                                VNĐ
                              </span>
                            </div>
                          </div>
                          <div className={`${styles.kensaSummary_Row} pb-0`}>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold`}
                            >
                              <span>Thanh toán cho thợ</span>
                              <KensaTooltip dataKey="thanh_toan_cho_tho" />
                            </div>
                            <div
                              className={`${styles.kensaSummary_Col} font-weight-bold text-success`}
                            >
                              <span>
                                {(
                                  serviceFee +
                                  otherFee +
                                  areaSubFee -
                                  discountFee -
                                  round(
                                    (serviceFee + otherFee - discountFee) *
                                      (50 / 100)
                                  )
                                ).toLocaleString()}{" "}
                                VNĐ
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default OrderExpertisePrice;
