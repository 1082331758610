import styles from "./index.module.scss";
import React, { Fragment, useEffect, useState } from "react";
import axios from "../../utils/axios";
import LoadingIcon from "../LoadingIcon";
import OrderList from "../OrderList";
import ROUTE from "../../utils/routes";
import moment from "moment";
import { toCapitalize } from "../../utils/functions";
import { AccessToken, OrderStatus } from "@Utils/enums";
import { Link, useHistory } from "react-router-dom";

function OrderExpertiseItem({ order }) {
  return (
    <>
      <div className="row">
        <div className="col-12 text-left">
          <h6>
            {order.vehicleName} {order.status == OrderStatus.CONFIRMED && <span className="text-danger">NEW</span>}
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col text-left">{toCapitalize(moment(order.appointmentTime, "DD/MM/YYYY hh:mm:ss").format("dddd, DD/MM HH:mm"))}</div>
        <div className="col-auto text-right">{order.area}</div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="mb-0">Địa chỉ: {order.appointmentLocation}</p>
        </div>
      </div>
      {order.note && (
        <div className="row">
          <div className="col-12">
            <p className="mb-0">
              Ghi chú: <i>{order.note}</i>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

function OrderExpertiseList() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    axios
      .get("orders/technician/expertise")
      .then((response) => {
        const data = response.data.data;
        data.sort((a, b) => {
          const momentA = moment(a.appointmentTime, "DD/MM/YYYY hh:mm:ss");
          const momentB = moment(b.appointmentTime, "DD/MM/YYYY hh:mm:ss");
          if(momentA.diff(moment()) > 0 && momentB.diff(moment()) > 0) {
            return momentA.valueOf() - momentB.valueOf();
          } else {
            return momentB.valueOf() - momentA.valueOf();
          }
        });
        
        setOrders(data || []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem(AccessToken.TOKEN_KEY);
          history.push(ROUTE.TECHNICIAN_LOGIN);
        } else {
          setError(error.response ? error.response.data.message : error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading ? (
        <>
          <LoadingIcon />
        </>
      ) : (
        <>
          {error || orders.length == 0 ? (
            <div className="p-3 text-center text-danger">{error || "Không tìm thấy đơn hàng nào."}</div>
          ) : (
            <OrderList>
              {orders.map((order, index) => (
                <Fragment key={order.id}>
                  <OrderList.Item url={ROUTE.TECHNICIAN_EXPERTISE_DETAIL.replace(":code", order.code)} index={index} order={order}>
                    <OrderList.Info>
                      <OrderExpertiseItem order={order} />
                    </OrderList.Info>
                  </OrderList.Item>
                </Fragment>
              ))}
            </OrderList>
          )}
          <div className="text-center p-3">
            <Link to={ROUTE.TECHNICIAN_HOME}>Quay về trang chủ</Link>
          </div>
        </>
      )}
    </>
  );
}

OrderExpertiseList.Item = OrderExpertiseItem;

export default OrderExpertiseList;
