import Checkbox from "rc-checkbox";
import NumberFormat from "react-number-format";
import bodyStyles from "@Layouts/Body/index.module.scss";
import styles from "../../pages/OrderConfirm/index.module.scss";
import axios from "../../utils/axios";
import LoadingIcon from "@Components/LoadingIcon";
import { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { LicensePlateArea, SellerType } from "@Utils/enums";
import Select from "react-select";
import { isImage } from "@Utils/validate";
import $ from "jquery";

const licenseOptions = [
  { value: LicensePlateArea.CITY, label: "Biển số thành phố" },
  { value: LicensePlateArea.PROVINCE, label: "Biển số tỉnh" },
];

const sellerTypeOptions = [
  { value: SellerType.PERSONAL, label: "Cá nhân" },
  { value: SellerType.SHOP, label: "Cửa hàng" },
];

export default class OrderFindBikeComplete extends Component {
  constructor(props) {
    super(props);
    this.previewModal = createRef();
    this.state = {
      loading: false,
      success: false,
      code: this.props.code,
      secret: this.props.secret,
      paymentMethod: null,
      purchaseStatus: null,
      searchStatus: null,
      commission: null,
      findBikeFee: null,
      purchaseReason: "",
      vehicleName: "",
      vehicleEngine: "",
      vehicleColor: "",
      vehicleOdometer: null,
      vehicleYearManu: null,
      vehiclePrice: null,
      vehicleImage: null,
      vehicleImageUrl: null,
      vehicleLicensePlateArea: null,
      vehicleLicensePlateImage: null,
      vehicleLicensePlateImageUrl: null,
      sellerType: null,
      sellerName: "",
      sellerAddress: "",
      errorPaymentMethod: "",
      errorCommission: "",
      errorPurchaseStatus: "",
      errorPurchaseReason: "",
      errorSearchStatus: "",
      errorFindBikeFee: "",
      errorVehicleName: "",
      errorVehicleEngine: "",
      errorVehicleColor: "",
      errorVehicleOdometer: "",
      errorVehicleYearManu: "",
      errorVehiclePrice: "",
      errorVehicleImage: "",
      errorVehicleLicensePlateArea: "",
      errorVehicleLicensePlateImage: "",
      errorSellerType: "",
      errorSellerName: "",
      errorSellerAddress: "",
    };
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      errorPaymentMethod: "",
      errorCommission: "",
      errorPurchaseStatus: "",
      errorPurchaseReason: "",
      errorSearchStatus: "",
      errorFindBikeFee: "",
      errorVehicleName: "",
      errorVehicleEngine: "",
      errorVehicleColor: "",
      errorVehicleOdometer: "",
      errorVehicleYearManu: "",
      errorVehiclePrice: "",
      errorVehicleImage: "",
      errorVehicleLicensePlateArea: "",
      errorVehicleLicensePlateImage: "",
      errorSellerType: "",
      errorSellerName: "",
      errorSellerAddress: "",
    });

    const {
      code,
      secret,
      purchaseStatus,
      searchStatus,
      commission,
      paymentMethod,
      purchaseReason,
      findBikeFee,
      vehicleName,
      vehicleEngine,
      vehicleColor,
      vehicleOdometer,
      vehicleYearManu,
      vehiclePrice,
      vehicleImage,
      vehicleLicensePlateArea,
      vehicleLicensePlateImage,
      sellerType,
      sellerName,
      sellerAddress,
    } = this.state;

    let hasError = false;
    if (paymentMethod === null) {
      this.setState({
        errorPaymentMethod: "Mời chọn phương thức thanh toán của khách hàng",
      });
      hasError = true;
    }
    if (commission === null || commission === "") {
      this.setState({
        errorCommission: "Mời nhập Phí bảo hành. Phí bảo hành tối thiểu là 0",
      });
      hasError = true;
    }
    if (purchaseStatus === null) {
      this.setState({
        errorPurchaseStatus: "Mời chọn khách có mua xe hay không",
      });
      hasError = true;
    }
    if (searchStatus === null) {
      this.setState({
        errorSearchStatus: "Mời chọn tiến độ tìm xe",
      });
      hasError = true;
    }
    if (purchaseStatus !== 1) {
      if (purchaseReason === "") {
        this.setState({
          errorPurchaseReason: "Mời nhập lý do khách không mua xe",
        });
        hasError = true;
      } else if (purchaseReason.trim().length < 30) {
        this.setState({
          errorPurchaseReason: "Mời nhập lý do khách không mua xe tối thiểu 30 ký tự",
        });
        hasError = true;
      }
    }

    if (this.props.order.isCreateFromExpertise && purchaseStatus !== 1) {
      if (findBikeFee === null || findBikeFee === "") {
        this.setState({
          errorFindBikeFee: "Mời nhập phí thẩm định. ",
        });
        hasError = true;
      } else {
        if (findBikeFee < 0) {
          this.setState({
            errorFindBikeFee: "Phí thẩm định tối thiểu là 0.",
          });
          hasError = true;
        }
      }
    }

    if (purchaseStatus === 1) {
      if (vehicleName === null || vehicleName === "") {
        this.setState({
          errorVehicleName: "Mời nhập tên xe",
        });
        hasError = true;
      }

      if (vehicleEngine === null || vehicleEngine === "") {
        this.setState({
          errorVehicleEngine: "Mời nhập động cơ",
        });
        hasError = true;
      } else if (vehicleEngine.toString().length < 2 || vehicleEngine.toString().length > 3) {
        this.setState({
          errorVehicleEngine: "Động cơ không hợp lẹ. VD: 50, 100, 125, 150...",
        });
        hasError = true;
      }

      if (vehicleColor === null || vehicleColor === "") {
        this.setState({
          errorVehicleColor: "Mời nhập màu xe",
        });
        hasError = true;
      }

      if (vehicleOdometer === null || vehicleOdometer === "") {
        this.setState({
          errorVehicleOdometer: "Mời nhập số km đã đi",
        });
        hasError = true;
      }

      if (vehicleYearManu === null || vehicleYearManu === "") {
        this.setState({
          errorVehicleYearManu: "Mời nhập năm sản xuất",
        });
        hasError = true;
      } else if (
        vehicleYearManu.toString().length !== 4 ||
        vehicleYearManu < 1900 ||
        vehicleYearManu > new Date().getFullYear()
      ) {
        this.setState({
          errorVehicleYearManu: "Năm sản xuất không hợp lệ. VD: 1999, 2020...",
        });
        hasError = true;
      }

      if (vehiclePrice === null || vehiclePrice === "") {
        this.setState({
          errorVehiclePrice: "Mời nhập giá xe",
        });
        hasError = true;
      } else if (vehiclePrice <= 0) {
        this.setState({
          errorVehiclePrice: "Giá xe không hợp lệ. Giá xe tối thiểu là 0",
        });
        hasError = true;
      }

      if (vehicleImage === null || vehicleImage === "") {
        this.setState({
          errorVehicleImage: "Mời nhập hình ảnh xe",
        });
        hasError = true;
      }

      if (vehicleLicensePlateArea === null || vehicleLicensePlateArea === "") {
        this.setState({
          errorVehicleLicensePlateArea: "Mời chọn loại biển số xe",
        });
        hasError = true;
      }

      if (vehicleLicensePlateImage === null || vehicleLicensePlateImage === "") {
        this.setState({
          errorVehicleLicensePlateImage: "Mời nhập hình ảnh biển số xe",
        });
        hasError = true;
      }

      if (sellerType === null || sellerType === "") {
        this.setState({
          errorSellerType: "Mời chọn loại người bán",
        });
        hasError = true;
      } else if (sellerType === SellerType.SHOP) {
        if (sellerName === null || sellerName === "") {
          this.setState({
            errorSellerName: "Mời nhập tên người bán",
          });
          hasError = true;
        }

        if (sellerAddress === null || sellerAddress === "") {
          this.setState({
            errorSellerAddress: "Mời nhập địa chỉ người bán",
          });
          hasError = true;
        }
      }

      if (vehicleImage === null) {
        this.setState({
          errorVehicleImage: "Mời nhập hình ảnh xe",
        });
        hasError = true;
      } else if (!isImage(vehicleImage)) {
        this.setState({
          errorVehicleImage: "Hình ảnh xe không hợp lệ",
        });
        hasError = true;
      }

      if (vehicleLicensePlateImage === null) {
        this.setState({
          errorVehicleLicensePlateImage: "Mời nhập hình ảnh biển số xe",
        });
        hasError = true;
      } else if (!isImage(vehicleLicensePlateImage)) {
        this.setState({
          errorVehicleLicensePlateImage: "Hình ảnh xe không hợp lệ",
        });
        hasError = true;
      }
    }

    if (hasError) {
      return false;
    }
    if (purchaseStatus === 1) {
      this.openPreviewModal();
    } else {
      this.onConfirm();
    }
    return false;
  }

  componentDidMount() {
    $(this.previewModal.current).on("hidden.bs.modal", function () {
      $(".modal-backdrop").remove();
    });
  }

  componentWillUnmount() {
    $(this.previewModal.current).off("hidden.bs.modal");
  }

  openPreviewModal() {
    $(this.previewModal.current).modal("show");
  }

  closePreviewModal() {
    $(this.previewModal.current).modal("hide");
  }

  onConfirm() {
    this.closePreviewModal();
    this.setState({
      loading: true,
    });

    const {
      code,
      secret,
      purchaseStatus,
      searchStatus,
      commission,
      paymentMethod,
      purchaseReason,
      findBikeFee,
      vehicleName,
      vehicleEngine,
      vehicleColor,
      vehicleOdometer,
      vehicleYearManu,
      vehiclePrice,
      vehicleImage,
      vehicleLicensePlateArea,
      vehicleLicensePlateImage,
      sellerType,
      sellerName,
      sellerAddress,
    } = this.state;

    axios
      .post(
        "/orders/find-bike/confirm",
        this.createFormData({
          code: code,
          confirm_token: secret,
          search_status: searchStatus,
          purchase_status: purchaseStatus,
          commission: commission,
          payment_method: paymentMethod,
          purchase_reason: purchaseReason,
          find_bike_fee: findBikeFee,
          vehicle_name: vehicleName,
          vehicle_engine: vehicleEngine,
          vehicle_color: vehicleColor,
          vehicle_odometer: vehicleOdometer,
          vehicle_year_manu: vehicleYearManu,
          vehicle_price: vehiclePrice,
          vehicle_image: vehicleImage,
          vehicle_license_plate_area: vehicleLicensePlateArea,
          vehicle_license_plate_image: vehicleLicensePlateImage,
          seller_type: sellerType,
          seller_name: sellerName,
          seller_address: sellerAddress,
        })
      )
      .then((res) => {
        this.setState({
          success: true,
        });
      })
      .catch((err) => {
        alert(err.response ? err.response.data.message : err.message);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  createFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    });
    return formData;
  }

  onPurchaseStatusChange(val) {
    this.setState({
      purchaseStatus: val,
      errorPurchaseStatus: "",
      errorPurchaseReason: "",
      errorFindBikeFee: "",
    });
  }
  onSearchStatusChange(val) {
    this.setState({
      searchStatus: val,
      errorSearchStatus: "",
    });
  }
  onMethodChange(val) {
    this.setState({
      paymentMethod: val,
      errorPaymentMethod: "",
    });
  }

  onCommissionChange(val) {
    this.setState({
      commission: val !== "" ? parseInt(val.replaceAll(",", "")) : null,
      errorCommission: "",
    });
  }

  onReasonChange(val) {
    this.setState({
      purchaseReason: val,
      errorPurchaseReason: "",
    });
  }

  onFindBikeFeeChange(val) {
    this.setState({
      findBikeFee: val !== "" ? parseInt(val.replaceAll(",", "")) : null,
      errorFindBikeFee: "",
    });
  }

  onVehicleNameChange(val) {
    this.setState({
      vehicleName: val,
      errorVehicleName: "",
    });
  }
  onVehicleEngineChange(val) {
    this.setState({
      vehicleEngine: val,
      errorVehicleEngine: "",
    });
  }
  onVehicleYearManuChange(val) {
    this.setState({
      vehicleYearManu: val,
      errorVehicleYearManu: "",
    });
  }
  onVehiclePriceChange(val) {
    this.setState({
      vehiclePrice: val !== "" ? parseInt(val.replaceAll(",", "")) : null,
      errorVehiclePrice: "",
    });
  }
  onVehicleLicensePlateAreaChange(val) {
    this.setState({
      vehicleLicensePlateArea: val,
      errorVehicleLicensePlateArea: "",
    });
  }
  onSellerTypeChange(val) {
    this.setState({
      sellerType: val,
      errorSellerType: "",
    });
  }
  onSellerNameChange(val) {
    this.setState({
      sellerName: val,
      errorSellerName: "",
    });
  }
  onSellerAddressChange(val) {
    this.setState({
      sellerAddress: val,
      errorSellerAddress: "",
    });
  }
  onVehicleColorChange(val) {
    this.setState({
      vehicleColor: val,
      errorVehicleColor: "",
    });
  }
  onVehicleOdometerChange(val) {
    this.setState({
      vehicleOdometer: val !== "" ? parseInt(val.replaceAll(",", "")) : null,
      errorVehicleOdometer: "",
    });
  }

  async onVehicleImageChange(files) {
    if (files.length > 0) {
      this.setState({
        vehicleImage: files[0],
        errorVehicleImage: "",
      });
      const fileUrl = await this.loadImage(files[0]);
      this.setState({
        vehicleImageUrl: fileUrl,
      });
    }
  }

  async onVehicleLicensePlateImageChange(files) {
    if (files.length > 0) {
      this.setState({
        vehicleLicensePlateImage: files[0],
        errorVehicleLicensePlateImage: "",
      });
      const fileUrl = await this.loadImage(files[0]);
      this.setState({
        vehicleLicensePlateImageUrl: fileUrl,
      });
    }
  }

  loadImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  render() {
    const order = this.props.order;
    const {
      success,
      loading,
      searchStatus,
      paymentMethod,
      purchaseStatus,
      purchaseReason,
      commission,
      findBikeFee,
      vehicleName,
      vehicleEngine,
      vehicleColor,
      vehicleOdometer,
      vehicleYearManu,
      vehiclePrice,
      vehicleImage,
      vehicleLicensePlateArea,
      vehicleLicensePlateImage,
      sellerType,
      sellerName,
      sellerAddress,
      errorPaymentMethod,
      errorCommission,
      errorPurchaseStatus,
      errorPurchaseReason,
      errorSearchStatus,
      errorFindBikeFee,
      errorVehicleName,
      errorVehicleEngine,
      errorVehicleColor,
      errorVehicleOdometer,
      errorVehicleYearManu,
      errorVehiclePrice,
      errorVehicleImage,
      errorVehicleLicensePlateArea,
      errorVehicleLicensePlateImage,
      errorSellerType,
      errorSellerName,
      errorSellerAddress,
    } = this.state;

    return (
      <>
        {loading && <LoadingIcon />}
        {!loading && success && (
          <>
            <div className={bodyStyles.kensaBody_Full}>
              <div className={styles.kensaOrder_Detail}>
                <div className="text-center text-success font-weight-bold py-3">
                  <p className="text-success font-weight-bold mb-3">Cập nhật trạng thái cho đơn hàng thành công</p>
                  <Link to={"/technician/find-bike"}>Quay về trang chủ</Link>
                </div>
              </div>
            </div>
          </>
        )}
        {!loading && !success && (
          <div className={bodyStyles.kensaBody_Full}>
            <form className={styles.kensaOrder_Confirm} onSubmit={this.onSubmit.bind(this)}>
              <div className="row form-group">
                <div className="col-12">
                  <h5 className="font-weight-bold mb-3">Thông tin đơn hàng</h5>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Tìm xe:</label>
                    <label className="col-12 col-md-8 col-form-label">
                      {order.vehicleManufactureName} {order.vehicleName}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Deadline:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.deadline}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Ngân sách:</label>
                    <label className="col-12 col-md-8 col-form-label">
                      {order.budgetStart.toLocaleString()} - {order.budgetEnd.toLocaleString()}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Biển số:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.licensePlateArea}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Màu sắc:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.vehicleColors.join(", ")}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Phiên bản:</label>
                    <label className="col-12 col-md-8 col-form-label">{order.vehicleVersion}</label>
                  </div>
                </div>
              </div>

              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">
                  Phương thức thanh toán phí còn lại?
                </label>
                <div className="col-md-8 d-flex align-items-center">
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={paymentMethod === 1} onChange={(e) => this.onMethodChange(1)} />
                    Tiền mặt
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox className="mr-2" checked={paymentMethod === 2} onChange={(e) => this.onMethodChange(2)} />
                    Chuyển khoản
                  </label>
                </div>
                {errorPaymentMethod && (
                  <div className="col-12 col-md-8 ml-md-auto text-danger">{errorPaymentMethod}</div>
                )}
              </div>
              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Phí bảo hành:</label>
                <div className="col-12 col-md-8">
                  <NumberFormat
                    className="form-control"
                    defaultValue={commission}
                    thousandSeparator={true}
                    onChange={(e) => this.onCommissionChange(e.target.value)}
                    placeholder="Nhập phí bảo hành"
                  />
                </div>
                {errorCommission && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorCommission}</div>}
              </div>
              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Tìm được xe hay không?</label>
                <div className="col-md-8 d-flex align-items-center">
                  <label className="col-4 col-form-label">
                    <Checkbox
                      className="mr-2"
                      checked={searchStatus !== null && searchStatus === 1}
                      onChange={(e) => this.onSearchStatusChange(1)}
                    />
                    Đã tìm được
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox
                      className="mr-2"
                      checked={searchStatus !== null && searchStatus === 2}
                      onChange={(e) => this.onSearchStatusChange(2)}
                    />
                    Vẫn đang tìm
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox
                      className="mr-2"
                      checked={searchStatus !== null && searchStatus === 0}
                      onChange={(e) => this.onSearchStatusChange(0)}
                    />
                    Không tìm được
                  </label>
                </div>
                {errorSearchStatus && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorSearchStatus}</div>}
              </div>
              <div className="row form-group">
                <label className="col-12 col-md-4 col-form-label font-weight-bold">Khách có mua xe hay không?</label>
                <div className="col-md-8 d-flex align-items-center">
                  <label className="col-4 col-form-label">
                    <Checkbox
                      className="mr-2"
                      checked={purchaseStatus !== null && purchaseStatus === 1}
                      onChange={(e) => this.onPurchaseStatusChange(1)}
                    />
                    Đã mua
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox
                      className="mr-2"
                      checked={purchaseStatus !== null && purchaseStatus === 2}
                      onChange={(e) => this.onPurchaseStatusChange(2)}
                    />
                    Đang cân nhắc
                  </label>
                  <label className="col-4 col-form-label">
                    <Checkbox
                      className="mr-2"
                      checked={purchaseStatus !== null && purchaseStatus === 0}
                      onChange={(e) => this.onPurchaseStatusChange(0)}
                    />
                    Không mua
                  </label>
                </div>
                {errorPurchaseStatus && (
                  <div className="col-12 col-md-8 ml-md-auto text-danger">{errorPurchaseStatus}</div>
                )}
              </div>
              {purchaseStatus !== 1 && (
                <>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Phí thẩm định:</label>
                    <div className="col-12 col-md-8">
                      <NumberFormat
                        className="form-control"
                        defaultValue={findBikeFee}
                        thousandSeparator={true}
                        onChange={(e) => this.onFindBikeFeeChange(e.target.value)}
                        placeholder="Nhập phí thẩm định"
                      />
                    </div>
                    {errorFindBikeFee && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorFindBikeFee}</div>
                    )}
                  </div>
                </>
              )}
              {purchaseStatus !== 1 && (
                <div className="row form-group">
                  <label className="col-12 col-md-4 col-form-label font-weight-bold">Lý do khách không mua xe:</label>
                  <div className="col-12 col-md-8">
                    <textarea
                      className="form-control"
                      onChange={(e) => this.onReasonChange(e.target.value)}
                      defaultValue={purchaseReason}
                      placeholder="Hãy ghi chi tiết, để công ty chăm sóc khách hàng chu đáo"
                    ></textarea>
                  </div>
                  {errorPurchaseReason && (
                    <div className="col-12 col-md-8 ml-md-auto text-danger">{errorPurchaseReason}</div>
                  )}
                </div>
              )}
              {purchaseStatus === 1 && (
                <>
                  <div className="row form-group">
                    <div className="col-12">
                      <h5 className="font-weight-bold mb-3">Thông tin xe đã tìm</h5>
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Tên xe:</label>
                    <div className="col-12 col-md-8">
                      <input
                        className="form-control"
                        onChange={(e) => this.onVehicleNameChange(e.target.value)}
                        defaultValue={vehicleName}
                        placeholder="Ví dụ: AB, NVX, SH Ý..."
                      />
                    </div>
                    {errorVehicleName && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleName}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Động cơ:</label>
                    <div className="col-12 col-md-8">
                      <input
                        className="form-control"
                        onChange={(e) => this.onVehicleEngineChange(e.target.value)}
                        defaultValue={vehicleEngine}
                        maxLength={3}
                        type="number"
                        placeholder="Ví dụ: 150..."
                      />
                    </div>
                    {errorVehicleEngine && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleEngine}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Năm sản xuất:</label>
                    <div className="col-12 col-md-8">
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => this.onVehicleYearManuChange(e.target.value)}
                        defaultValue={vehicleYearManu}
                        maxLength={4}
                        placeholder="Ví dụ: 2019..."
                      />
                    </div>
                    {errorVehicleYearManu && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleYearManu}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Giá khách mua:</label>
                    <div className="col-12 col-md-8">
                      <NumberFormat
                        className="form-control"
                        defaultValue={vehiclePrice}
                        thousandSeparator={true}
                        onChange={(e) => this.onVehiclePriceChange(e.target.value)}
                        placeholder="Nhập giá khách mua"
                      />
                    </div>
                    {errorVehiclePrice && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehiclePrice}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Biển số:</label>
                    <div className="col-12 col-md-8">
                      <Select
                        options={licenseOptions}
                        isSearchable={false}
                        defaultValue={
                          vehicleLicensePlateArea
                            ? { value: vehicleLicensePlateArea, label: vehicleLicensePlateArea === LicensePlateArea.CITY ? 'Biển số thành phố' : 'Biển số tỉnh' }
                            : null
                        }
                        classNamePrefix="kensaSelect"
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onVehicleLicensePlateAreaChange(e.value)}
                        placeholder="Chọn biển số khu vực..."
                      />
                    </div>
                    {errorVehicleLicensePlateArea && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleLicensePlateArea}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Người bán:</label>
                    <div className="col-12 col-md-8">
                      <Select
                        options={sellerTypeOptions}
                        isSearchable={false}
                        defaultValue={sellerType ? { value: sellerType, label: sellerType === SellerType.PERSONAL ? 'Cá nhân' : 'Cửa hàng' } : null}
                        classNamePrefix="kensaSelect"
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSellerTypeChange(e.value)}
                        placeholder="Chọn loại người bán..."
                      />
                    </div>
                    {errorSellerType && <div className="col-12 col-md-8 ml-md-auto text-danger">{errorSellerType}</div>}
                  </div>
                  {sellerType === SellerType.SHOP && (
                    <>
                      <div className="row form-group">
                        <label className="col-12 col-md-4 col-form-label font-weight-bold">Tên cửa hàng:</label>
                        <div className="col-12 col-md-8">
                          <input
                            className="form-control"
                            onChange={(e) => this.onSellerNameChange(e.target.value)}
                            defaultValue={sellerName}
                            placeholder="Nhập tên cửa hàng"
                          />
                        </div>
                        {errorSellerName && (
                          <div className="col-12 col-md-8 ml-md-auto text-danger">{errorSellerName}</div>
                        )}
                      </div>
                      <div className="row form-group">
                        <label className="col-12 col-md-4 col-form-label font-weight-bold">Địa chỉ cửa hàng:</label>
                        <div className="col-12 col-md-8">
                          <input
                            className="form-control"
                            onChange={(e) => this.onSellerAddressChange(e.target.value)}
                            defaultValue={sellerAddress}
                            placeholder="Nhập địa chỉ cửa hàng"
                          />
                        </div>
                        {errorSellerAddress && (
                          <div className="col-12 col-md-8 ml-md-auto text-danger">{errorSellerAddress}</div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Màu xe:</label>
                    <div className="col-12 col-md-8">
                      <input
                        className="form-control"
                        onChange={(e) => this.onVehicleColorChange(e.target.value)}
                        defaultValue={vehicleColor}
                        placeholder="Nhập màu xe"
                      />
                    </div>
                    {errorVehicleColor && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleColor}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">ODO hiển thị lúc giao xe:</label>
                    <div className="col-12 col-md-8">
                      <NumberFormat
                        className="form-control"
                        defaultValue={vehicleOdometer}
                        thousandSeparator={true}
                        onChange={(e) => this.onVehicleOdometerChange(e.target.value)}
                        placeholder="Nhập ODO hiển thị lúc giao xe"
                      />
                    </div>
                    {errorVehicleOdometer && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleOdometer}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">
                      Ảnh chụp bên hông xe (thấy toàn bộ xe):
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this.onVehicleImageChange(e.target.files)}
                        // defaultValue={vehicleImage}
                        style={{ padding: "0.175rem 0.75rem" }}
                      />
                    </div>
                    {errorVehicleImage && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleImage}</div>
                    )}
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-md-4 col-form-label font-weight-bold">Ảnh biển số xe:</label>
                    <div className="col-12 col-md-8">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this.onVehicleLicensePlateImageChange(e.target.files)}
                        // defaultValue={vehicleLicensePlateImage}
                        style={{ padding: "0.175rem 0.75rem" }}
                      />
                    </div>
                    {errorVehicleLicensePlateImage && (
                      <div className="col-12 col-md-8 ml-md-auto text-danger">{errorVehicleLicensePlateImage}</div>
                    )}
                  </div>
                </>
              )}
              <div className="row form-group">
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-success">
                    Xác nhận
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="modal fade" ref={this.previewModal}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Xác nhận thông tin xe đã tìm</h4>
                <button className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p className="text-danger font-weight-bold">
                  Vui lòng xác nhận thông tin xe đã tìm trước khi gửi báo cáo.
                </p>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th>Tên xe</th>
                      <td>{vehicleName}</td>
                    </tr>
                    <tr>
                      <th>Động cơ xe (cc)</th>
                      <td>{vehicleEngine}</td>
                    </tr>
                    <tr>
                      <th>Năm sản xuất</th>
                      <td>{vehicleYearManu}</td>
                    </tr>
                    <tr>
                      <th>Màu xe</th>
                      <td>{vehicleColor}</td>
                    </tr>
                    <tr>
                      <th>Giá khách mua</th>
                      <td>{vehiclePrice && vehiclePrice.toLocaleString()} VNĐ</td>
                    </tr>
                    <tr>
                      <th>ODO lúc giao xe</th>
                      <td>{vehicleOdometer && vehicleOdometer.toLocaleString()} km</td>
                    </tr>
                    <tr>
                      <th>Người bán</th>
                      <td>{sellerType == SellerType.SHOP ? "Cửa hàng" : "Cá nhân"}</td>
                    </tr>
                    {sellerType == SellerType.SHOP && (
                      <>
                        <tr>
                          <th>Tên cửa hàng</th>
                          <td>{sellerName}</td>
                        </tr>
                        <tr>
                          <th>Địa chỉ cửa hàng</th>
                          <td>{sellerAddress}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <th>Biển số</th>
                      <td>{vehicleLicensePlateArea}</td>
                    </tr>
                    <tr>
                      <th>Ảnh xe</th>
                      <td>
                        <img src={this.state.vehicleImageUrl} style={{ height: "100px" }} />
                      </td>
                    </tr>
                    <tr>
                      <th>Ảnh biển số</th>
                      <td>
                        <img src={this.state.vehicleLicensePlateImageUrl} style={{ height: "100px" }} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer text-right">
                <button className="btn btn-secondary" data-dismiss="modal">
                  Đóng
                </button>
                <button className="btn btn-success" onClick={this.onConfirm.bind(this)}>
                  Xác nhận
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
