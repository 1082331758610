import { Component } from "react";
import { PaymentStatus } from "../../utils/enums";

export default class OrderFindBikeDetail extends Component {
  render() {
    const {
      code,
      serviceName,
      name,
      servicePrice,
      prepay,
      remainingFee,
      vehicleManufactureName,
      vehicleTypeName,
      vehicleColors,
      vehicleVersion,
      specialRequirement,
      licensePlateArea,
      budgetStart,
      budgetEnd,
      statusDesc,
      customerName,
      customerPhone,
      technicianName,
      coupon,
      paymentStatus,
      createdBy,
      createdAt
    } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Tên khách hàng
              </label>
              <label className="col-form-label col-12 col-md-6">
                {customerName}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Số điện thoại
              </label>
              <label className="col-form-label col-12 col-md-6">
                <a href={`tel:${customerPhone}`}>{customerPhone}</a>
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Mã đơn hàng:
              </label>
              <label className="col-form-label col-12 col-md-6">{code}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Đơn hàng:
              </label>
              <label className="col-form-label col-12 col-md-6">{name}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Dịch vụ:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {serviceName}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Phí dịch vụ:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {servicePrice.toLocaleString()} VNĐ
              </label>
            </div>
          </div>
          {paymentStatus === PaymentStatus.PAID && (
            <>
              <div className="col-12 col-md-6">
                <div className="row form-group">
                  <label className="col-form-label col-12 col-md-6 font-weight-bold">
                    Đã đặt cọc:
                  </label>
                  <label className="col-form-label col-12 col-md-6">
                    {prepay.toLocaleString()} VNĐ
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row form-group">
                  <label className="col-form-label col-12 col-md-6 font-weight-bold">
                    Phí còn lại:
                  </label>
                  <label className="col-form-label col-12 col-md-6">
                    {remainingFee.toLocaleString()} VNĐ
                  </label>
                </div>
              </div>
            </>
          )}
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Hãng xe
              </label>
              <label className="col-form-label col-12 col-md-6">
                {vehicleManufactureName}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Loại xe
              </label>
              <label className="col-form-label col-12 col-md-6">
                {vehicleTypeName}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Ngân sách
              </label>
              <label className="col-form-label col-12 col-md-6">
                {budgetStart.toLocaleString()} VNĐ -{" "}
                {budgetEnd.toLocaleString()} VNĐ
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Phiên bản
              </label>
              <label className="col-form-label col-12 col-md-6">
                {vehicleVersion}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Màu xe
              </label>
              <label className="col-form-label col-12 col-md-6">
                {vehicleColors}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Biển số
              </label>
              <label className="col-form-label col-12 col-md-6">
                {licensePlateArea}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Kỹ thuật viên:</label>
              <label className="col-form-label col-12 col-md-6">{technicianName}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Ngày tạo đơn:</label>
              <label className="col-form-label col-12 col-md-6">{createdAt}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">Người tạo:</label>
              <label className="col-form-label col-12 col-md-6">{createdBy}</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Yêu cầu đặc biệt
              </label>
              <label className="col-form-label col-12 col-md-6">
                {specialRequirement ? specialRequirement : "Không"}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Mã giới thiệu:
              </label>
              <label className="col-form-label col-12 col-md-6">
                {coupon ? coupon : "Không"}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <label className="col-form-label col-12 col-md-6 font-weight-bold">
                Trạng thái đơn hàng
              </label>
              <label
                className="col-form-label col-12 col-md-6"
                dangerouslySetInnerHTML={{ __html: statusDesc }}
              ></label>
            </div>
          </div>
        </div>
      </>
    );
  }
}
