import { Component } from "react";
import styles from "./index.module.scss";
import { MdDone } from "react-icons/md";
export default class KensaRadio extends Component {

  render() {
    const { name, label, value ,subLabel,  currentValue} = this.props;
    return (
      <div className={styles.kensaRadio_Group}>
        <input
          type="radio"
          className={styles.kensaRadio_Input}
          name={name}
          value={value}
          checked={currentValue === value}
          hidden
          id={`${name}_${value}`}
          onChange={this.props.onChange}
        />
        <label className={styles.kensaRadio_Label} htmlFor={`${name}_${value}`}>
          <MdDone size={30} />
          <span className={styles.kensaRadio_Name}>{label}</span>
          <span className={styles.kensaRadio_Sub}>{subLabel}</span>
        </label>
      </div>
    );
  }
}
