function handlerError(err) {
    let message = "";
    if (this.state.error) {
        message = this.state.error;
    } else {
        if (err.response) {
            message = err.response.data.message ? err.response.data.message : err.response.statusText;
        } else {
            message = err.message;
        }
    }

    this.setState({
        error: message,
        loading: false
    });
}
export {
    handlerError
}