import styles from "./index.module.scss";
import React, { Fragment, useEffect, useState } from "react";
import axios from "../../utils/axios";
import LoadingIcon from "../LoadingIcon";
import OrderList from "../OrderList";
import ROUTE from "../../utils/routes";
import moment from "moment";
import { toCapitalize } from "../../utils/functions";
import { AccessToken, OrderStatus } from "@Utils/enums";
import { Link, useHistory } from "react-router-dom";

function OrderFindBikeItem({ order }) {
  return (
    <>
      <div className="row">
        <div className="col-12 text-left">
          <h6>
            Tìm xe {order.vehicleName} - {order.vehicleVersion} {order.status == OrderStatus.CONFIRMED && <span className="text-danger">NEW</span>}
          </h6>
        </div>
        <div className="col-12">
          {order.startBudget.toLocaleString("en")}đ - {order.endBudget.toLocaleString("en")}đ
        </div>
      </div>
      <div className="row">
        <div className="col text-left">
          Hạn chót: <span className={order.serviceType == "TIM_XE" && moment(order.deadline, "DD/MM hh:mm:ss").diff(moment(), "days") <= 3 ? "text-danger" : ""}>{toCapitalize(moment(order.deadline, "DD/MM/YYYY hh:mm:ss").format("dddd, DD/MM/YYYY"))}</span>
        </div>
      </div>
    </>
  );
}

function OrderFindBikeList() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    axios
      .get("orders/technician/find-bike")
      .then((response) => {
        const data = response.data.data;
        setOrders(data || []);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem(AccessToken.TOKEN_KEY);
          history.push(ROUTE.TECHNICIAN_LOGIN);
        } else {
          setError(error.response ? error.response.data.message : error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading ? (
        <>
          <LoadingIcon />
        </>
      ) : (
        <>
          {error || orders.length == 0 ? (
            <div className="p-3 text-center text-danger">{error || "Không tìm thấy đơn hàng nào."}</div>
          ) : (
            <OrderList>
              {orders.map((order, index) => (
                <Fragment key={order.id}>
                  <OrderList.Item url={ROUTE.TECHNICIAN_FIND_BIKE_DETAIL.replace(":code", order.code)} index={index} order={order}>
                    <OrderList.Info>
                      <OrderFindBikeItem order={order} />
                    </OrderList.Info>
                  </OrderList.Item>
                </Fragment>
              ))}
            </OrderList>
          )}
          <div className="text-center p-3">
            <Link to={ROUTE.TECHNICIAN_HOME}>Quay về trang chủ</Link>
          </div>
        </>
      )}
    </>
  );
}

OrderFindBikeList.Item = OrderFindBikeItem;

export default OrderFindBikeList;
