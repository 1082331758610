import axios from 'axios';
import { AccessToken } from './enums';

const API_HOST = process.env.REACT_APP_API_HOST;
const API_URL = `${API_HOST}/api/v1`;

const Axios = axios.create({
    baseURL: `${API_URL}/`,
});

Axios.interceptors.request.use(function (config) {
    if(localStorage.getItem(AccessToken.TOKEN_KEY)) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem(AccessToken.TOKEN_KEY)}`;
    }
    return config;
});

export default Axios;
