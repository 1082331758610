import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Body from "@Layouts/Body";
import Footer from "@Layouts/Footer";
import Header from "@Layouts/Header";
import CheckOriginPage from "./pages/CheckOrigin";
import ExpertisePage from "./pages/Expertise";
import FindBikePage from "./pages/FindBike";
import OrderPage from "./pages/Order";
import OrderConfirmPage from "./pages/OrderConfirm";
import OrderDetailPage from "./pages/OrderDetail";
import PaymentReturn from "./pages/PaymentReturn";
import TechnicianPage from "./pages/Technician";
import ROUTE from "./utils/routes";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Body>
        <ScrollToTop />
        <Switch>
          <Route exact path={ROUTE.HOME} component={OrderPage} />
          <Route path={ROUTE.TECHNICIAN} component={TechnicianPage} />
          <Route path={ROUTE.ORDER_EXPERTISE} component={ExpertisePage} />
          <Route path={ROUTE.ORDER_CHECK_ORIGIN} component={CheckOriginPage} />
          <Route path={ROUTE.ORDER_FIND_BIKE} component={FindBikePage} />
          <Route path={ROUTE.ORDER_PAYMENT_RETURN} component={PaymentReturn} />
          <Route path={ROUTE.ORDER_CONFIRM} component={OrderConfirmPage} />
          <Route path={ROUTE.ORDER_DETAIL} component={OrderDetailPage} />
          <Redirect to={ROUTE.HOME} />
        </Switch>
      </Body>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
