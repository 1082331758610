import { Component } from "react";
import bodyStyles from "@Layouts/Body/index.module.scss";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import ExpertiseVehiclePage from "./pages/ExpertiseVehicle";
import ExpertiseAppointmentPage from "./pages/ExpertiseAppointment";
import ExpertiseCustomerPage from "./pages/ExpertiseCustomer";
import LoadingIcon from "@Components/LoadingIcon";
import axios from "../../utils/axios";
import { handlerError } from "../../utils/request";
import OrderExpertisePrice from "@Components/OrderExpertisePrice";
import OrderExpertiseConfirm from "@Components/OrderExpertiseConfirm";
import ROUTE from "../../utils/routes";
import ScrollToTop from '../../utils/ScrollToTop';
import React from "react";
class ExpertisePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleManufacture: null,
      vehicleModel: null,
      vehicleType: null,
      vehicleName: null,
      areas: [],
      servicePrice: null,
      prepaidRate: 50,
      paymentMethod: null,
      appointmentTime: "",
      appointmentLocation: "",
      note: "",
      customerName: "",
      customerPhone: "",
      coupon: "",
      discount: 0,
      discountType: null,
      discountValue: 0,
      loading: false,
      openingConfirm: false,
      error: "",
    };
  }

  updateOrder(data) {
    this.setState(data);
  }
  confirmOrder() {
    this.setState({
      openingConfirm: true,
    });
  }
  submitOrder() {
    this.setState({
      loading: true,
    });
    const {
      vehicleManufacture,
      vehicleType,
      vehicleName,
      servicePrice,
      areas,
      appointmentLocation,
      appointmentTime,
      customerName,
      customerPhone,
      note,
      coupon
    } = this.state;
    const data = {
      vehicle_manufacture_id: parseInt(vehicleManufacture),
      vehicle_name: vehicleName,
      vehicle_type_id: parseInt(vehicleType),
      service_price_id: parseInt(servicePrice),
      appointment_location: appointmentLocation,
      appointment_time: appointmentTime,
      customer_name: customerName,
      customer_phone: customerPhone,
      coupon: coupon.toUpperCase(),
      area_ids: areas.map((a) => parseInt(a)),
      note: note,
      
    };
    axios
      .post("/orders/expertise", data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/order/" + res.data.data.code);
      })
      .catch(handlerError.bind(this));
  }

  render() {
    const order = this.state;
    const { loading, openingConfirm } = this.state;
    return (
      <>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="col-12 p-0">
              <h1 className={bodyStyles.kensaBody_Title}>Đặt Lịch Thẩm Định Xe</h1>
            </div>
            <BrowserRouter>
              <div className={bodyStyles.kensaBody_Left}>
                <ScrollToTop />
                <Switch>
                  <Redirect
                    exact
                    from={ROUTE.ORDER_EXPERTISE}
                    to={ROUTE.ORDER_EXPERTISE_VEHICLE}
                  />
                  <Route
                    path={ROUTE.ORDER_EXPERTISE_VEHICLE}
                    render={() => (
                      <ExpertiseVehiclePage
                        order={order}
                        updateOrder={this.updateOrder.bind(this)}
                      />
                    )}
                  />
                  <Route
                    path={ROUTE.ORDER_EXPERTISE_APPOINTMENT}
                    render={() => (
                      <ExpertiseAppointmentPage
                        order={order}
                        updateOrder={this.updateOrder.bind(this)}
                      />
                    )}
                  />
                  <Route
                    path={ROUTE.ORDER_EXPERTISE_CUSTOMER}
                    render={() => (
                      <ExpertiseCustomerPage
                        order={order}
                        updateOrder={this.updateOrder.bind(this)}
                        confirmOrder={this.confirmOrder.bind(this)}
                      />
                    )}
                  />
                </Switch>
              </div>
              <div className={bodyStyles.kensaBody_Right}>
                <OrderExpertisePrice showBox={true} order={order} updateOrder={this.updateOrder.bind(this)}/>
              </div>
            </BrowserRouter>
          </>
        )}
        {openingConfirm && (
          <>
            <OrderExpertiseConfirm
              order={this.state}
              submitOrder={this.submitOrder.bind(this)}
              updateOrder={this.updateOrder.bind(this)}
              modalClose={(() => {
                this.setState({ openingConfirm: false });
              })}
            />
          </>
        )}
      </>
    );
  }
}
export default withRouter(ExpertisePage);
