import { Component, createRef } from "react";
import { withRouter } from "react-router";
import LoadingIcon from "../LoadingIcon";
import axios from "../../utils/axios";
import $ from "jquery";
import styles from "./index.module.scss";

class OrderCheckOriginConfirm extends Component {
  constructor(props) {
    super(props);
    this.modal = createRef();
    this.submitOrder = this.props.submitOrder;
    this.modalClose = this.props.modalClose;
    this.state = {
      loading: false,
      dataServicePrices: [],
    };
  }
  componentDidMount() {
    this.setState({
        loading: true,
      });
    $(this.modal.current).modal("show");
    $(this.modal.current).on("hidden.bs.modal", () => {
      $(".modal-backdrop").remove();
      this.modalClose();
    });
    Promise.all([
      this.getServicePrices(),
    ]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }
  onConfirm(){
    $(this.modal.current).modal("hide");
    this.submitOrder();
  }
  getServicePrices() {
    return new Promise((resovle, reject) => {
      axios
        .get("/services/prices", {
          params: {
            service_code: "CHECK_GOC",
          },
        })
        .then((res) => {
          this.setState({
            dataServicePrices: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          this.setState({
            loadingPrice: false,
          });
          resovle(true);
        });
    });
  }
  servicePrice() {
    const { servicePrice } = this.props.order;
    const { dataServicePrices } = this.state;
    if (dataServicePrices.filter((sp) => sp.id === servicePrice).length > 0) {
      return dataServicePrices.filter((sp) => sp.id === servicePrice)[0].price;
    }
    return 0;
  }
  discountFee() {
    const serviceFee = this.servicePriceValue(this.props.order.servicePrice);
    const countVehicle = this.props.order.licensePlates.length;
    const { discountType, discountValue } = this.props.order;
    if (discountType === 1) {
      return serviceFee * countVehicle * (discountValue / 100);
    } else {
      return discountValue;
    }
  }
  render() {
      const { loading } = this.state;
      const { licensePlates, customerPhone, customerName, note } = this.props.order;
      return(
        <div
        className={`modal fade ${styles.kensaConfirm_Modal}`}
        id="confirmModal"
        ref={this.modal}
      >
        <div className={`modal-dialog ${styles.kensaConfirm_ModalDialog}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Xác nhận đơn hàng</h3>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              {loading ? (
                <LoadingIcon />
              ) : (
                <>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Tên khách hàng
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {customerName}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Số điện thoại
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {customerPhone}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Dịch vụ:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          Check gốc
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Phí dịch vụ:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {this.servicePrice().toLocaleString()} VNĐ / xe
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Số lượng xe
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {licensePlates.length}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Biển số cần kiểm:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {licensePlates.join(', ')}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row form-group">
                        <label className="col-form-label col-12 col-md-6 font-weight-bold">
                          Ghi chú:
                        </label>
                        <label className="col-form-label col-12 col-md-6">
                          {note}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="col-12 text-center">
              <button type="button" className="btn btn-primary text-uppercase" disabled={loading} onClick={this.onConfirm.bind(this)}>
                Xác nhận đăng ký dịch vụ
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
  }
}

export default withRouter(OrderCheckOriginConfirm);
