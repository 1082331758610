import LoadingIcon from "@Components/LoadingIcon";
import OrderExpertiseAction from "@Components/OrderExpertiseAction";
import OrderExpertiseDetail from "@Components/OrderExpertiseDetail";
import bodyStyles from "@Layouts/Body/index.module.scss";
import Axios from "@Utils/axios";
import { AccessToken } from "@Utils/enums";
import ROUTE from "@Utils/routes";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./index.module.scss";

function TechnicianExpertiseDetailPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [order, setOrder] = useState(null);
  const { code } = useParams();
  const history = useHistory();

  const getOrder = () => {
    return new Promise((resolve, reject) => {
      Axios.get("/orders/technician/expertise/" + code)
        .then((res) => {
          const order = res.data.data;
          document.title = `Đơn hàng thẩm định xe ${order.vehicleName}`;
          setOrder({ ...order, code: code });
        })
        .catch((err) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(AccessToken.TOKEN_KEY);
            history.push(ROUTE.TECHNICIAN_LOGIN);
          } else {
            setError(err.response ? err.response.data.message : err.message);
          }
        })
        .finally(() => {
          resolve(true);
        });
    });
  };

  useEffect(() => {
    (async () => {
      await getOrder();
      setLoading(false);
    })();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <div className="col-12 p-0">
            <h1 className={bodyStyles.kensaBody_Title}>{order ? order.name : "Không tìm thấy đơn hàng"}</h1>
          </div>

          {!order ? (
            <>
              <div className={bodyStyles.kensaBody_Full}>
                <div className={styles.kensaOrder_Detail}>
                  <div className="text-center text-danger font-weight-bold mb-3">{error}</div>
                  <div className="text-center">
                    <Link className="btn btn-primary" to={ROUTE.TECHNICIAN_EXPERTISE}>
                      Quay về trang danh sách
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={bodyStyles.kensaBody_Full}>
                <div className={styles.kensaOrder_Detail}>
                  <OrderExpertiseDetail {...order} />
                </div>
                <div className={styles.kensaOrder_Footer}>
                  <div className="col-12 text-center">
                    <OrderExpertiseAction order={order} />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default TechnicianExpertiseDetailPage;
