import { Component } from "react";

export default class OrderExpertiseTechnician extends  Component {
    render() {
        const {technicianName, technicianPhone} = this.props;
        return (
            <>
                <div className="row form-group">
          <div className="col-12 font-weight-bold">
            <p>Đơn hàng của quý khách đã được thanh toán thành công, Kỹ thuật viên của Kensa sẽ liên hệ với quý khách trong ít phút. </p>
            <p>Thông tin kỹ thuật viên: </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row form-group">
              <label className="col-form-label col-lg-6 font-weight-bold">
                Tên kỹ thuật viên
              </label>
              <label className="col-form-label col-lg-6">
                {technicianName}
              </label>
            </div>
          </div>
          <div className="col-12 col-lg-6">
             <div className="row form-group">
              <label className="col-form-label col-lg-6 font-weight-bold">
                SDT kỹ thuật viên
              </label>
              <label className="col-form-label col-lg-6">
                {technicianPhone}
              </label>
            </div>
          </div>
        </div>
            </>
        )
    }
}