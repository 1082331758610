function round(money) {
  return parseInt((money / 1000).toFixed()) * 1000;
}

function toCapitalize(str) {
  let arr = str.split(" ");
  arr = arr.map((s) => {
    let sArr = s.split("");
    sArr[0] = sArr[0].toUpperCase();
    return sArr.join("");
  });
  return arr.join(" ");
}
export { round, toCapitalize };
