import { Component } from "react";
import { round } from "../../utils/functions";
import styles from './index.module.scss';
export default class OrderExpertisePrepayDetail extends Component {

    render() {
        const { price, subFee, discountFee, otherFee } = this.props;
        const prepay = round((price + otherFee - discountFee) * 50 / 100);
        return (
            <>
                <div className={styles.kensaPrepay_Box}>
                    <div className={styles.kensaPrepay_Title}>
                        Tiền cọc
                    </div>
                    <div className={`${styles.kensaPrepay_Value} ${styles.kensaPrepay_Color}`}>
                        {prepay.toLocaleString()} VNĐ
                    </div>
                </div>
                <div className={styles.kensaPrepay_Box}>
                    <div className={styles.kensaPrepay_Title}>
                        Thanh toán cho Kỹ thuật viên sau khi kiểm tra
                    </div>
                    <div className={`${styles.kensaPrepay_Value}`}>
                        {((price + otherFee - discountFee + subFee) - prepay).toLocaleString()} VNĐ
                    </div>
                </div>
            </>
        )
    }
}