import { Component, createRef } from "react";
import $ from "jquery";
import styles from "./index.module.scss";
import axios from "../../utils/axios";
import { IoIosCloseCircleOutline } from "react-icons/io";
export default class KensaCoupon extends Component {
  constructor(props) {
    super(props);
    this.modal = createRef();
    this.updateOrder = this.props.updateOrder;
    this.state = {
      isCouponValid: false,
      tempCoupon: "",
      errorCoupon: "",
      isRequesting: false,
    };
  }

  componentDidMount() {
    $(this.modal.current).on("hidden.bs.modal", function () {
      $(".modal-backdrop").remove();
    });
  }
  componentWillUnmount() {
    $(this.modal.current).off("hidden.bs.modal");
  }
  openModal() {
    $(this.modal.current).modal("show");
  }
  closeModal() {
    $(this.modal.current).modal("hide");
  }
  onChange(e) {
    this.setState({
      tempCoupon: e.target.value.toUpperCase(),
    });
  }
  onSubmit(e) {
    e.preventDefault();
    const { tempCoupon } = this.state;
    if (tempCoupon && tempCoupon.length === 6) {
      this.setState({
        isRequesting: true,
      });
      axios
        .post("/coupon/verify", {
          coupon: tempCoupon,
        })
        .then((res) => {
          const data = res.data.data;
          this.updateOrder({
            coupon: data.code,
            discountType: data.discountType,
            discountValue: data.discountValue,
          });
          this.setState({
            isCouponValid: true,
            isRequesting: false,
            errorCoupon: "",
          });
          this.closeModal();
        })
        .catch((err) => {
          this.setState({
            isCouponValid: false,
            isRequesting: false,
            errorCoupon: err.response ? err.response.data.message : err.message,
          });
        });
    } else {
      this.setState({
        isCouponValid: false,
        errorCoupon: "Định dạng mã khuyến mãi không đúng",
      });
    }
    return false;
  }
  discountFee() {
    const { serviceFee } = this.props;
    const { discountType, discountValue } = this.props.order;
    if (discountType === 1) {
      return serviceFee * (discountValue / 100);
    } else {
      return discountValue;
    }
  }
  removeCoupon() {
    this.updateOrder({
      coupon: "",
      discountValue: 0,
      discountType: null,
    });
    this.setState({
      tempCoupon: "",
      errorCoupon: "",
      isCouponValid: false,
    });
  }
  render() {
    const { tempCoupon, errorCoupon, isRequesting } = this.state;
    const { coupon } = this.props.order;
    const { discountFee } = this.props;
    return (
      <>
        {!coupon ? (
          <>
            <button
              type="button"
              className={styles.kensaCoupon_Button}
              onClick={this.openModal.bind(this)}
            >
              Mã khuyến mãi
            </button>
          </>
        ) : (
          <>
            <div className={styles.KensaCoupon_Col}>
              Mã <span className="font-weight-bold">{coupon}</span>
              <button type="button" className={styles.kensaCoupon_Remove} title="Xóa mã khuyến mãi" onClick={this.removeCoupon.bind(this)} ><IoIosCloseCircleOutline size={20}  /></button>
            </div>
            <div className={styles.KensaCoupon_Col}>
              - {discountFee.toLocaleString()} VNĐ
            </div>
          </>
        )}
        <div className="modal fade" ref={this.modal}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Mã khuyến mãi</h4>
                <button className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={this.onSubmit.bind(this)}>
                  <div className="row mb-2">
                    <div className="col-auto flex-grow-1">
                      <input
                        type="text"
                        className="form-control"
                        value={tempCoupon}
                        maxLength="6"
                        onChange={this.onChange.bind(this)}
                        disabled={isRequesting}
                      />
                    </div>
                    <div className="col-auto">
                      <button type="submit" className="btn btn-primary" disabled={isRequesting}>
                        Áp dụng
                      </button>
                    </div>
                  </div>
                  {errorCoupon && (
                    <div className="row">
                      <div className="col-12 text-center text-danger">
                        {errorCoupon}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
