import { Component } from "react";
import { withRouter } from "react-router";
import styles from "./index.module.scss";

class OrderFormAction extends Component {
  constructor(props) {
    super(props);
    this.prev = this.props.prev;
    this.state = {
      submitText: this.props.submitText ? this.props.submitText : "Tiếp tục",
      backText: this.props.backText ? this.props.backText : "Quay lại"
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.canSubmit !== prevProps.canSubmit) {
      this.setState({ canSubmit: this.props.canSubmit });
    }
  }
  render() {
    const { submitText, backText } = this.state;
    return (
      <div className={styles.kensaForm_Action_Container}>
        {this.prev && (
          <button
            type="button"
            className={`mr-auto btn btn-secondary ${styles.kensaForm_Action_Button} ${styles.kensaForm_Action_Prev}`}
            onClick={this.prev}
          >
            {backText}
          </button>
        )}
        <button
          type="submit"
          className={`ml-auto btn btn-success ${styles.kensaForm_Action_Button} ${styles.kensaForm_Action_Next}`}
        >
          {submitText}
        </button>
      </div>
    );
  }
}
export default withRouter(OrderFormAction);
