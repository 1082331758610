import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "@Components/PrivatedRoute";
import ROUTE from "../../utils/routes";
import TechnicianExpertisePage from "./pages/Expertise";
import TechnicianFindBikePage from "./pages/FindBike";
import TechnicianLoginPage from "./pages/Login";
import TechnicianHomePage from "./pages/Home";
import TechnicianExpertiseDetailPage from "./pages/ExpertiseDetail";
import TechnicianFindBikeDetailPage from "./pages/FindBikeDetail";

export default function TechnicianPage() {
  return (
    <>
      <Switch>
        <Route path={ROUTE.TECHNICIAN_LOGIN} render={() => <TechnicianLoginPage />} />
        <PrivateRoute path={ROUTE.TECHNICIAN_HOME}>
          <TechnicianHomePage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.TECHNICIAN_EXPERTISE} exact={true} >
          <TechnicianExpertisePage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.TECHNICIAN_EXPERTISE_DETAIL}>
          <TechnicianExpertiseDetailPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.TECHNICIAN_FIND_BIKE} exact={true}>
          <TechnicianFindBikePage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE.TECHNICIAN_FIND_BIKE_DETAIL}>
          <TechnicianFindBikeDetailPage />
        </PrivateRoute>
        <Redirect to={ROUTE.TECHNICIAN_HOME} />
      </Switch>
    </>
  );
}
