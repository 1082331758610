import { Component } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import logo from "../../assets/logo.png";
import BayImage from "../../assets/mr-bay.jpg";
import TungImage from "../../assets/mr-tung.jpg";
import TuanImage from "../../assets/mr-tuan.jpg";
import styles from "./index.module.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const mockTechnicians = [
  {
    id: 1,
    name: "Mr. Mạnh Tùng",
    imageUrl: TungImage,
    phone: "SĐT hiển thị sau khi đặt lịch",
  },
  {
    id: 2,
    name: "Mr. Văn Bảy",
    imageUrl: BayImage,
    phone: "SĐT hiển thị sau khi đặt lịch",
  },
  {
    id: 3,
    name: "Mr. Anh Tuấn",
    imageUrl: TuanImage,
    phone: "SĐT hiển thị sau khi đặt lịch",
  },
  {
    id: 4,
    name: "Mr. Nam Anh",
    imageUrl: logo,
    phone: "SĐT hiển thị sau khi đặt lịch",
  },
  {
    id: 5,
    name: "Mr. Quốc Nam",
    imageUrl: logo,
    phone: "SĐT hiển thị sau khi đặt lịch",
  },
];
const TechnicianInfo = (props) => {
  return (
    <div className="col-12 col-lg-4 text-center mb-5">
      <div className={styles.kensaTech_Info}>
        <div className={styles.kensaTech_Image}>
          <img src={props.imageUrl} alt={props.name} />
        </div>
        <div className={styles.kensaTech_Name}>{props.name}</div>
        <div className={`${styles.kensaTech_Phone} text-danger`}>{props.phone}</div>
      </div>
    </div>
  );
};

export default class TechnicianSlider extends Component {
  render() {
    const windowWidth = window.innerWidth;
    return (
      <>
        {windowWidth > 576 ? (
          <>
            <div className="row justify-content-center">
              {mockTechnicians.map((tech) => {
                return (
                  <TechnicianInfo
                    key={tech.id}
                    name={tech.name}
                    imageUrl={tech.imageUrl}
                    phone={tech.phone}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <>
            <Swiper
              spaceBetween={15}
              slidesPerView={1}
              navigation={{clickable: true}}
              pagination={{ clickable: true }}
            >
              {mockTechnicians.map((tech) => {
                return (
                  <SwiperSlide key={tech.id}>
                    <TechnicianInfo
                      key={tech.id}
                      name={tech.name}
                      imageUrl={tech.imageUrl}
                      phone={tech.phone}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </>
    );
  }
}
