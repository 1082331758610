import useAuth from "@Hooks/use-auth";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import ROUTE from "src/utils/routes";
import axios from "../../utils/axios";
import { AccessToken } from "../../utils/enums";

function TechnicianLoginForm() {
  const [isVerifying, user, isLoggedIn, setIsVerifying] = useAuth();
  const history = useHistory();

  const [data, setData] = useState({
    phone: "",
    password: "",
    remember: true,
  });
  const [error, setError] = useState({
    phone: "",
    password: "",
    submit: "",
  });
  const [loading, setLoading] = useState(false);

  const validateData = () => {
    const error = {
      phone: "",
      password: "",
      submit: "",
    };
    if (!data.phone) {
      error.phone = "Mời nhập số điện thoại.";
    } else {
      const pattern = /(0[3|5|7|8|9])+([0-9]{8})/;
      if (!pattern.test(data.phone)) {
        error.phone = "Định dạng SĐT không hợp lệ. VD: 0772845728";
      }
    }

    if (!data.password) {
      error.password = "Mời nhập mật khẩu đăng nhập.";
    }
    return error;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const error = validateData();
    if (Object.keys(error).filter((key) => !!error[key]).length > 0) {
      setError(error);
      return false;
    }
    setLoading(true);
    axios
      .post("/auth/login", data)
      .then((response) => {
        const token = response.data.data.access_token;
        localStorage.setItem(AccessToken.TOKEN_KEY, token);
        window.location.href = window.location.origin + ROUTE.TECHNICIAN_HOME;
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setError({
            ...error,
            ...{
              submit: error.response.data.message,
            },
          });
        } else {
          setError({
            ...error,
            ...{
              submit: error.message,
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return false;
  };

  useEffect(() => {
    setError({
      phone: "",
      password: "",
      submit: "",
    });
  }, [data]);

  return (
    <form className={"kensaForm_Container"} onSubmit={(e) => onSubmit(e)}>
      <div className="kensaForm_Content">
        <div className="row form-group">
          <label className="col-12 col-lg-4 col-form-label">Số điện thoại:</label>
          <div className="col-12 col-lg-8">
            <input type="text" className={`form-control ${error.phone ? "invalid" : ""}`} name="phone" defaultValue={""} placeholder="077xxxxxxx" maxLength={10} onChange={(e) => setData({ ...data, ...{ phone: e.target.value } })} />
            {error.phone && <div className="invalid-feedback">{error.phone}</div>}
          </div>
        </div>
        <div className="row form-group">
          <label className="col-12 col-lg-4 col-form-label">Mật khẩu:</label>
          <div className="col-12 col-lg-8">
            <input type="password" className={`form-control ${error.password ? "invalid" : ""}`} name="password" defaultValue={""} placeholder="*******" onChange={(e) => setData({ ...data, ...{ password: e.target.value } })} />
            {error.password && <div className="invalid-feedback">{error.password}</div>}
          </div>
        </div>
        {error.submit && (
          <div className="row form-group">
            <div className="col-12 text-center">
              <div className="invalid-feedback">{error.submit}</div>
            </div>
          </div>
        )}
        <div className="row form-group">
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-success" disabled={loading}>
              Đăng Nhập
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(TechnicianLoginForm);
