import { Component } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import KensaTooltip from "@Components/KensaTooltip";
import OrderFormAction from "@Components/OrderFormAction";
import ROUTE from "../../../../utils/routes";
import { WithContext as ReactTags } from "react-tag-input";
import { checkFieldValid } from "../../../../utils/validate";
const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];
class FindBikeRequirement extends Component {
  constructor(props) {
    super(props);
    this.updateOrder = this.props.updateOrder;
    this.checkFieldValid = checkFieldValid.bind(this);
    const {
      vehicleManufacture,
      vehicleName,
      vehicleType,
      servicePrice,
    } = this.props.order;
    if (!vehicleName || !vehicleType || !vehicleManufacture || !servicePrice) {
      this.props.history.push(ROUTE.ORDER_FIND_BIKE_VEHICLE);
    }
    this.state = {
      errorBudgetStart: "",
      errorBudgetEnd: "",
      errorBudget: "",
      errorLicensePlateArea: "",
      errorVehicleVersion: "",
      errorVehicleColor: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      const {
        errorBudgetStart,
        errorBudgetEnd,
        errorBudget,
        errorLicensePlateArea,
        errorVehicleVersion,
        errorVehicleColor,
      } = this.state;
      if (
        errorBudgetStart ||
        errorBudgetEnd ||
        errorBudget ||
        errorLicensePlateArea ||
        errorVehicleVersion ||
        errorVehicleColor
      ) {
        this.checkFormValid();
      }
    }
  }

  checkFormValid() {
    const {
      budgetStart,
      budgetEnd,
      licensePlateArea,
      vehicleVersion,
      vehicleColors,
    } = this.props.order;
    let hasError = false;
    if (
      !this.checkFieldValid(
        "errorBudgetStart",
        budgetStart,
        "Mời nhập ngân sách tối thiểu"
      )
    )
      hasError = true;
    if (
      !this.checkFieldValid(
        "errorBudgetEnd",
        budgetEnd,
        "Mời nhập ngân sách tối đa"
      )
    )
      hasError = true;
    if (!hasError) {
      if (budgetStart >= budgetEnd) {
        this.setState({
          errorBudget: "Ngân sách tối thiểu phải nhỏ hơn ngân sách tối đa",
        });
        hasError = true;
      } else {
        this.setState({
          errorBudget: "",
        });
      }
    }
    if (
      !this.checkFieldValid(
        "errorLicensePlateArea",
        licensePlateArea,
        "Mời chọn loại biển số theo khu vực"
      )
    )
      hasError = true;
    if (
      !this.checkFieldValid(
        "errorVehicleVersion",
        vehicleVersion,
        "Mời nhập phiên bản xe"
      )
    )
      hasError = true;
    if (
      !this.checkFieldValid(
        "errorVehicleColor",
        vehicleColors,
        "Mời nhập màu xe, tối thiểu hai màu"
      )
    )
      hasError = true;
    return !hasError;
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.checkFormValid()) {
      return false;
    }
    this.props.history.push(ROUTE.ORDER_FIND_BIKE_CUSTOMER);
    return false;
  }

  onTextChange(e, name) {
    this.updateOrder({
      [`${name}`]:
        name === "budgetStart" || name === "budgetEnd"
          ? parseInt(e.target.value)
          : e.target.value,
    });
  }

  onSelect(e, name) {
    this.updateOrder({
      [`${name}`]: e.value,
    });
  }

  onDeleteTag(i) {
    const { vehicleColors } = this.props.order;
    this.updateOrder({
      vehicleColors: vehicleColors.filter((lp, index) => index !== i),
    });
  }

  onAdditionTag(tag) {
    const vehicleColors = this.props.order.vehicleColors;
    vehicleColors.push(tag.id);
    this.updateOrder({
      vehicleColors: vehicleColors,
    });
  }

  onFocusOutTag(tag) {
    const vehicleColors = this.props.order.vehicleColors;
    if (tag && vehicleColors.indexOf(tag) === -1) {
      vehicleColors.push(tag);
      this.updateOrder({
        vehicleColors: vehicleColors,
      });
    }
  }

  onDragTag(tag, currPos, newPos) {
    const tags = this.props.order.vehicleColors;
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag.id);

    this.updateOrder({
      vehicleColors: newTags,
    });
  }

  prev() {
    this.props.history.push(ROUTE.ORDER_FIND_BIKE_VEHICLE);
  }

  render() {
    const {
      budgetStart,
      budgetEnd,
      licensePlateArea,
      vehicleVersion,
      vehicleColors,
      specialRequirement,
    } = this.props.order;
    const {
      errorBudget,
      errorBudgetEnd,
      errorBudgetStart,
      errorLicensePlateArea,
      errorVehicleColor,
      errorVehicleVersion,
    } = this.state;

    const vehicleColorTags = vehicleColors.map((c) => {
      return { id: c, text: c };
    });
    const licenseOptions = [
      { value: "Biển số thành phố", label: "Biển số thành phố" },
      { value: "Biển số tỉnh", label: "Biển số tỉnh" },
    ];
    return (
      <>
        <form
          className={"kensaForm_Container"}
          onSubmit={(e) => this.onSubmit(e)}
        >
          <div className="kensaForm_Content">
            <h1 className={`kensaForm_Title`}>Thông tin cơ bản xe cần tìm</h1>
            <div className="expertise-form">
              <div className="row form-group">
                <label className="col-12 col-form-label">
                  Ngân sách: <KensaTooltip dataKey="ngan_sach" />
                </label>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">Từ:</label>
                <div className="col-12 col-lg-8">
                  <input
                    type="number"
                    step="1000000"
                    className={`form-control ${
                      errorBudgetStart || errorBudget ? "invalid" : ""
                    }`}
                    defaultValue={budgetStart}
                    onChange={(e) => this.onTextChange(e, "budgetStart")}
                  />
                  {errorBudgetStart && (
                    <div className="invalid-feedback">{errorBudgetStart}</div>
                  )}
                </div>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">Đến:</label>
                <div className="col-12 col-lg-8">
                  <input
                    type="number"
                    step="1000000"
                    className={`form-control ${
                      errorBudgetEnd || errorBudget ? "invalid" : ""
                    }`}
                    defaultValue={budgetEnd}
                    onChange={(e) => this.onTextChange(e, "budgetEnd")}
                  />
                  {errorBudgetEnd && (
                    <div className="invalid-feedback">{errorBudgetEnd}</div>
                  )}
                  {errorBudget && (
                    <div className="invalid-feedback">{errorBudget}</div>
                  )}
                </div>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">
                  Biển số
                </label>
                <div className="col-12 col-lg-8">
                  <Select
                    options={licenseOptions}
                    defaultValue={
                      licensePlateArea
                        ? { value: licensePlateArea, label: licensePlateArea }
                        : null
                    }
                    classNamePrefix="kensaSelect"
                    className={errorLicensePlateArea ? "invalid-select" : ""}
                    menuPortalTarget={document.querySelector("body")}
                    onChange={(e) => this.onSelect(e, "licensePlateArea")}
                    placeholder="Chọn biển số khu vực..."
                  />
                  {errorLicensePlateArea && (
                    <div className="invalid-feedback">
                      {errorLicensePlateArea}
                    </div>
                  )}
                </div>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">
                  Phiên bản:
                </label>
                <div className="col-12 col-lg-8">
                  <input
                    type="text"
                    className={`form-control ${
                      errorVehicleVersion ? "invalid" : ""
                    }`}
                    defaultValue={vehicleVersion}
                    onChange={(e) => this.onTextChange(e, "vehicleVersion")}
                    placeholder="Ví dụ: ABS, CBS, 150cc"
                  />
                  {errorVehicleVersion && (
                    <div className="invalid-feedback">
                      {errorVehicleVersion}
                    </div>
                  )}
                </div>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">
                  Màu sắc:
                </label>
                <div className="col-12 col-lg-8">
                  <ReactTags
                    classNames={{
                      tagInputField: `form-control ${
                        errorVehicleColor ? "invalid" : ""
                      }`,
                    }}
                    placeholder='Màu sắc cách bởi dấu ","'
                    tags={vehicleColorTags}
                    delimiters={delimiters}
                    handleDelete={this.onDeleteTag.bind(this)}
                    handleAddition={this.onAdditionTag.bind(this)}
                    handleDrag={this.onDragTag.bind(this)}
                    handleInputBlur={this.onFocusOutTag.bind(this)}
                  />
                  {errorVehicleColor && (
                    <div className="invalid-feedback">{errorVehicleColor}</div>
                  )}
                </div>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">
                  Yêu cầu đặc biệt:
                </label>
                <div className="col-12 col-lg-8">
                  <textarea
                    type="text"
                    className="form-control"
                    defaultValue={specialRequirement}
                    onChange={(e) => this.onTextChange(e, "specialRequirement")}
                    placeholder="Ví dụ: Xe chưa bị tai nạn"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <OrderFormAction
            prev={this.prev.bind(this)}
            submitText="Đặt dịch vụ"
          />
        </form>
      </>
    );
  }
}

export default withRouter(FindBikeRequirement);
