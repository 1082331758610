import React from "react";
import { withRouter } from "react-router-dom";
import OrderFindBikeList from "@Components/OrderFindBikeList";
import bodyStyles from "@Layouts/Body/index.module.scss";

function TechnicianFindBikePage() {
  return (
    <>
      <div className="col-12 p-0">
        <h1 className={bodyStyles.kensaBody_Title}>Đơn Tìm Xe</h1>
        <div className={bodyStyles.kensaBody_Full}>
          <OrderFindBikeList />
        </div>
      </div>
    </>
  );
}

export default withRouter(TechnicianFindBikePage);
