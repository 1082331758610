import { Component } from "react";
import { withRouter } from "react-router";
import KensaTooltip from "../KensaTooltip";
import KensaRadio from "../KensaRadio";
import vnpay from "../../assets/vnpay.png";
import payoo from "../../assets/payoo.png";
import { round } from "../../utils/functions";
const paymentMethods = [
  {
    id: 2,
    name: "Chuyển khoản qua VNPAY",
    logo: vnpay,
    active: true,
  },
  {
    id: 3,
    name: "Chuyển khoản qua PAYOO",
    logo: payoo,
    active: false,
  },
  {
    id: 1,
    name: "Nộp tiền mặt tại công ty",
    logo: null,
    active: true,
  },
];
class OrderPayment extends Component {
  constructor(props) {
    super(props);
    this.startPayment = this.props.startPayment;
    this.updateState = this.props.updateState;
    this.paymentMethods = this.props.paymentMethods;
    this.paymentPrepayRatios = this.props.paymentPrepayRatios;
  }
  onSelect(e, name) {
    this.updateState({
      [`${name}`]: e.value,
    });
  }

  onChange(e) {
    this.updateState({
      [`${e.target.name}`]: parseInt(e.target.value),
    });
  }

  render() {
    const {
      paymentMethod,
      paymentPrepayRatio,
      paymentPrepayRatios,
      servicePrice,
      billingPeriod
    } = this.props;
    return (
      <>
        <div className="row form-group">
          <div className="col-12 font-weight-bold">
            <span className="text-danger">Để tiếp tục</span> đơn hàng, mời bạn đặt cọc. Hạn chót đặt cọc là {billingPeriod}
            <KensaTooltip dataKey="yeu_cau_thanh_toan" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row form-group justify-content-center">
              <label className="col-form-label col-lg-12 font-weight-bold mb-3">
                Vui lòng chọn mức đặt cọc:
                <KensaTooltip dataKey="yeu_cau_dat_coc" />
              </label>
              {paymentPrepayRatios.map((ratio, i) => {
                return (
                  <div className="col-6 col-lg-4">
                    <KensaRadio
                      key={i}
                      name="paymentPrepayRatio"
                      value={ratio.value}
                      label={`Cọc ${ratio.name}`}
                      subLabel={
                        round(((servicePrice * ratio.value) / 100)).toLocaleString() +
                        " VNĐ"
                      }
                      onChange={this.onChange.bind(this)}
                      currentValue={paymentPrepayRatio}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="row justify-content-center">
              <label className="col-form-label col-lg-12 font-weight-bold mb-3">
                Vui lòng chọn hình thức thanh toán:
              </label>
              {paymentMethods.map((method, i) => {
                return (
                  <>
                    {method.active && (
                      <>
                        <div className="col-6 col-md-4">
                          <KensaRadio
                            key={i}
                            name="paymentMethod"
                            value={method.id}
                            label={
                              <>
                                {method.logo && (
                                  <img src={method.logo} alt={method.name} />
                                )}
                              </>
                            }
                            subLabel={method.name}
                            onChange={this.onChange.bind(this)}
                            currentValue={paymentMethod}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              })}
              {paymentMethod === 1 && (
                <>
                  <div className="col-12 mt-3">
                    <div className="alert alert-success">
                      <h6
                        className="alert-heading font-weight-bold"
                        style={{ fontSize: "14px" }}
                      >
                        Địa chỉ công ty:{" "}
                      </h6>
                      <p className="mb-0">
                        Tòa nhà Work Not Work, 236/43/2 Đường Điện Biên Phủ,Quận
                        Bình Thạnh, TP Hồ Chí Minh
                        <a href="https://goo.gl/maps/JNKmwgWqSxux7Neu7" target="_blank" rel="noreferrer" className="d-block my-2">Google Map</a>
                        <p><strong>Giờ làm việc của Khối Văn Phòng:</strong> Thứ 2 - Thứ 6, từ 10h sáng đến 4h chiều.</p>
                        <p className="text-danger"><strong>Lưu ý: Quý khách vui lòng đặt cọc trước {billingPeriod}. Đơn hàng của quý khách sẽ Tự động hủy sau thời gian này, hoặc khi có Khách hàng khác đặt cọc trước.</strong> </p>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(OrderPayment);
