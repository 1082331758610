import { Component } from "react";
import { withRouter } from "react-router";
import KensaTooltip from "@Components/KensaTooltip";
import LoadingIcon from "@Components/LoadingIcon";
import OrderFormAction from "@Components/OrderFormAction";
import axios from "../../../../utils/axios";
import Select from "react-select";
import ROUTE from "../../../../utils/routes";
import { checkFieldValid } from "../../../../utils/validate";

class ExpertiseVehiclePage extends Component {
  constructor(props) {
    super(props);
    this.updateOrder = this.props.updateOrder;
    this.checkFieldValid = checkFieldValid.bind(this);
    this.state = {
      loadingData: true,
      dataVehicleTypes: [],
      dataVehicleManufactures: [],
      dataServicePrices: [],
      dataAreas: [],
      loadingPrice: false,
      errorVehicleManufacture: "",
      errorVehicleType: "",
      errorVehicleName: "",
      errorAreas: "",
      errorServicePrice: ""

    };
  }

  componentDidMount() {
    Promise.all([
      this.getVehicleTypes(),
      this.getVehicleManufactures(),
      this.getAreas(),
      this.getServicePrices()
    ]).then(() => {
      this.setState({
        loadingData: false,
      });
      
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      const { errorAreas, errorServicePrice, errorVehicleManufacture, errorVehicleName, errorVehicleType } = this.state;
      if(errorAreas || errorServicePrice || errorVehicleManufacture || errorVehicleName || errorVehicleType) {
        this.checkFormValid();
      }
    }
  }

  getVehicleTypes() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/types")
        .then((res) => {
          this.setState({
            dataVehicleTypes: res.data.data,
          });
          if(!this.props.order.vehicleType) {
            this.updateOrder({
              vehicleType: res.data.data[0].id,
            });
            this.getServicePrices().then(() => {
              this.updateOrder({
                servicePrice: this.state.dataServicePrices[0].id,
              });
              resovle(true);
            });
          } else {
            resovle(true);
          }
        })
        .catch((err) => {
          console.error(err.response);
        })
    });
  }

  getVehicleManufactures() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/manufactures")
        .then((res) => {
          this.setState({
            dataVehicleManufactures: res.data.data,
          });
          if(!this.props.order.vehicleManufacture){
            this.updateOrder({
              vehicleManufacture: res.data.data[0].id
            });
          }
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getAreas() {
    return new Promise((resovle, reject) => {
      axios
        .get("/areas")
        .then((res) => {
          this.setState({
            dataAreas: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getServicePrices() {
    const { vehicleType } = this.props.order;
    if (vehicleType) {
      this.setState({
        loadingPrice: true,
      });

      return new Promise((resovle, reject) => {
        axios
          .get("/services/prices", {
            params: {
              vehicle_type_id: vehicleType,
              service_code: "THAM_DINH_XE",
            },
          })
          .then((res) => {
            this.setState({
              dataServicePrices: res.data.data,
            });
          })
          .catch((err) => {
            console.error(err.response);
          })
          .finally(() => {
            this.setState({
              loadingPrice: false,
            });
            resovle(true);
          });
      });
    } else {
      return Promise.resolve(true);
    }
  }

  onSelect(e, name) {
    this.updateOrder({
      [`${name}`]: e.value,
    });
    if (name === "vehicleType" && e.value) {
      setTimeout(() => {
        this.updateOrder({
          servicePrice: null,
        });
        this.getServicePrices();
      }, 1);
    }
  }

  onTextChange(e) {
    this.updateOrder({
      [`${e.target.name}`]: e.target.value,
    });
  }
  onMultiSelect(array, name) {
    this.updateOrder({
      [`${name}`]: array.map((val) => val.value),
    });
  }

  checkFormValid() {
    const { vehicleManufacture, vehicleType, vehicleName, areas, servicePrice } = this.props.order;
    let hasError = false;
    if (!this.checkFieldValid('errorVehicleManufacture', vehicleManufacture, "Mời chọn hãng xe")) hasError = true;
    if (!this.checkFieldValid('errorVehicleType', vehicleType, "Mời chọn phân loại xe")) hasError = true;
    if (!this.checkFieldValid('errorVehicleName', vehicleName, "Mời nhập tên xe")) hasError = true;
    if (!this.checkFieldValid('errorAreas', areas, "Mời chọn khu vực xem xe")) hasError = true;
    if (!this.checkFieldValid('errorServicePrice', servicePrice, "Mời chọn số lượng xe cần xem")) hasError = true;
    return !hasError;
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.checkFormValid()) {
      return false;
    }
    
    this.props.history.push(ROUTE.ORDER_EXPERTISE_APPOINTMENT);
    return false;
  }

  render() {
    const {
      vehicleManufacture,
      vehicleName,
      vehicleType,
      servicePrice,
      areas,
      error,
    } = this.props.order;

    const {
      loadingData,
      dataVehicleManufactures,
      dataVehicleTypes,
      dataAreas,
      dataServicePrices,
      loadingPrice,
    } = this.state;
    let selectedVehicleManufacture =
      dataVehicleManufactures.filter((dv) => dv.id === vehicleManufacture)
        .length > 0
        ? dataVehicleManufactures.filter(
          (dv) => dv.id === vehicleManufacture
        )[0]
        : null;
    if (selectedVehicleManufacture) {
      selectedVehicleManufacture = {
        value: selectedVehicleManufacture.id,
        label: selectedVehicleManufacture.name,
      };
    }
    let selectedVehicleType =
      dataVehicleTypes.filter((dv) => dv.id === vehicleType).length > 0
        ? dataVehicleTypes.filter((dv) => dv.id === vehicleType)[0]
        : null;
    if (selectedVehicleType) {
      selectedVehicleType = {
        value: selectedVehicleType.id,
        label: selectedVehicleType.name,
      };
    }
    let selectedServicePrice =
      dataServicePrices.filter((dv) => dv.id === servicePrice).length > 0
        ? dataServicePrices.filter((dv) => dv.id === servicePrice)[0]
        : null;
    if (selectedServicePrice) {
      selectedServicePrice = {
        value: selectedServicePrice.id,
        label: selectedServicePrice.name,
      };
    }
    let selectedAreas =
      dataAreas.filter((city) => city.children.filter(child => areas.indexOf(child.id) > -1)).length > 0
        ? dataAreas.filter((city) => city.children.filter(child => areas.indexOf(child.id) > -1))[0].children.filter(a => areas.indexOf(a.id) > -1)
        : null;
    if (selectedAreas) {
      selectedAreas = selectedAreas.map((area) => {
        return {
          value: area.id,
          label: area.name,
        };
      });
    }
    const { errorVehicleManufacture, errorVehicleType, errorVehicleName, errorAreas, errorServicePrice } = this.state;
    return (
      <>
        {loadingData ? (
          <LoadingIcon />
        ) : (
          <>
            <form
              className={"kensaForm_Container"}
              onSubmit={(e) => this.onSubmit(e)}
            >
              <div className="kensaForm_Content">
                <h1 className={`kensaForm_Title`}>Thông tin xe</h1>
                <div className="expertise-form">
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Hãng xe: 
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder="Chọn hãng xe..."
                        options={dataVehicleManufactures.map((v) => {
                          return { value: v.id, label: v.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={errorVehicleManufacture ? 'invalid-select' : ''}
                        defaultValue={selectedVehicleManufacture}
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSelect(e, "vehicleManufacture")}
                      />
                      {errorVehicleManufacture && <div className="invalid-feedback">{errorVehicleManufacture}</div>}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Phân loại xe:
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder="Chọn phân loại xe..."
                        options={dataVehicleTypes.map((v) => {
                          return { value: v.id, label: v.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={errorVehicleType ? 'invalid-select' : ''}
                        defaultValue={selectedVehicleType}
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSelect(e, "vehicleType")}
                      />
                      {errorVehicleType && <div className="invalid-feedback">{errorVehicleType}</div>}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Tên xe:
                    </label>
                    <div className="col-12 col-lg-8">
                      <input
                        className={`form-control ${errorVehicleName ? 'invalid' : ''}`}
                        defaultValue={vehicleName}
                        name="vehicleName"
                        placeholder="Ví dụ: Xe SH Ý"
                        onChange={(e) => this.onTextChange(e)}
                      />
                      {errorVehicleName && <div className="invalid-feedback">{errorVehicleName}</div>}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Số lượng xe cần kiểm tra: <KensaTooltip dataKey="so_luong_xe_can_kiem_tra" />
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder={
                          vehicleType
                            ? "Chọn số lượng xe cần kiểm tra..."
                            : "Cần chọn loại xe trước"
                        }
                        options={dataServicePrices.map((v) => {
                          return { value: v.id, label: v.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={errorServicePrice ? 'invalid-select' : ''}
                        value={selectedServicePrice}
                        defaultValue={selectedServicePrice}
                        isDisabled={
                          loadingPrice || dataServicePrices.length === 0
                        }
                        isLoading={loadingPrice}
                        menuPortalTarget={document.querySelector("body")}
                        onChange={(e) => this.onSelect(e, "servicePrice")}
                      />
                      {errorServicePrice && <div className="invalid-feedback">{errorServicePrice}</div>}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Khu vực: <KensaTooltip dataKey="khu_vuc" />
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        placeholder="Chọn khu vực xem xe..."
                        options={selectedAreas.length === 2 ? [] : dataAreas.map((v) => {
                          return {
                            value: v.id,
                            label: `${v.name}`,
                            options: v.children.map((c) => {
                              return { value: c.id, label: `${c.name} ${c.subFee ? "- Phụ phí: " + c.subFee.toLocaleString() + " VNĐ" : ""}` };
                            }),
                          };
                        })}
                        noOptionsMessage={() => {
                          return selectedAreas.length === 2 ? 'Chỉ được chọn tối đa 2 Quận / Huyện' : 'Không có dữ liệu'
                        }}
                        classNamePrefix="kensaSelect"
                        className={errorAreas ? 'invalid-select' : ''}
                        defaultValue={selectedAreas}
                        menuPortalTarget={document.querySelector("body")}
                        isMulti={true}
                        onChange={(e) => this.onMultiSelect(e, "areas")}
                      />
                      { errorAreas && <div className="invalid-feedback">{errorAreas}</div>}
                    </div>
                  </div>
                  {error && (
                    <div className="row">
                      <div className="col-12 text-center text-danger">
                        {error}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <OrderFormAction
                prev={null}
              ></OrderFormAction>
            </form>
          </>
        )}
      </>
    );
  }
}
export default withRouter(ExpertiseVehiclePage);
