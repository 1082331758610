import "bootstrap";
import $ from "jquery";
import React, { Component, createRef } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import bodyStyles from "@Layouts/Body/index.module.scss";
import ROUTE from "../../utils/routes";
import styles from "./index.module.scss";
class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.modal = createRef();
    this.state = {
      code: "",
    };
  }
  componentDidMount(){
    document.title = "Trang chủ";
    if($('.modal-backdrop')) {
        $('.modal-backdrop').remove();
      }
  }
  openSearchModal() {
    $(this.modal.current).modal("show");
  }
  onChange(e, name) {
    this.setState({
      [name]: e.target.value,
    });
  }
  isValidCode( ) {
    const code = this.state.code;
    if(!code || code.length < 6 || code.length > 6) {
        return false;
    }
    return true;
  }
  onSubmitSearch(e) {
    e.preventDefault();
    const code = this.state.code;
    if(!code || code.length < 6 || code.length > 6) {
      alert("Mã đơn hàng không hợp lệ.");
      return false;
    }
    $(this.modal.current).modal("hide");
    this.props.history.push(`${ROUTE.ORDER}/${code.toUpperCase()}`);
    return false;
  }
  render() {
    return (
      <>
        <div className={bodyStyles.kensaBody_Full}>
          <div className={styles.kensaOrder_Content}>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-4">
                <Link
                  className={`${styles.kensaOrder_Card} ${styles.kensaOrder_Card_Expertise}`}
                  to={ROUTE.ORDER_EXPERTISE_VEHICLE}
                >
                  <span className={styles.kensaOrder_Card_Text}>
                    Đăng ký thẩm định xe
                  </span>
                </Link>
              </div>
              {/* <div className="col-12 col-lg-4">
                <Link
                  className={`${styles.kensaOrder_Card} ${styles.kensaOrder_Card_CheckOrigin}`}
                  to={ROUTE.ORDER_CHECK_ORIGIN}
                  disabled={true}
                >
                  <span className={styles.kensaOrder_Card_Text}>Check gốc</span>
                </Link>
              </div>
              <div className="col-12 col-lg-4">
                <Link
                  className={`${styles.kensaOrder_Card} ${styles.kensaOrder_Card_FindBike}`}
                  to={ROUTE.ORDER_FIND_BIKE}
                  disabled={true}
                >
                  <span className={styles.kensaOrder_Card_Text}>Tìm xe</span>
                </Link>
              </div> */}
              <div className="col-12 col-lg-4">
                <div
                  className={`${styles.kensaOrder_Card} ${styles.kensaOrder_Card_Search}`}
                  onClick={this.openSearchModal.bind(this)}
                >
                  <span className={styles.kensaOrder_Card_Text}>Tra cứu mã đơn hàng</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" ref={this.modal} id="searchModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Tra cứu đơn hàng</h3>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form className="row" onSubmit={this.onSubmitSearch.bind(this)}>
                  <div className="col-12 form-group">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => this.onChange(e, "code")}
                      placeholder="Nhập mã đơn hàng tại đây (6 ký tự)"
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary w-100" disabled={!this.isValidCode()}>
                      Tra cứu
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(OrderPage);
