import { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./index.module.scss";
class Body extends Component {
  render() {
    return (
      <div className={styles.kensaBody}>
        <div className={styles.kensaBody_Container}>
          <div
            className={
              styles.kensaBody_Content +
              (this.props.location.pathname == "/technician/login"
                ? " " + styles.kensaBody_ContentLimit
                : "")
            }
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Body);
