import { Component, createRef } from "react";
import styles from "./index.module.scss";
import $ from "jquery";
import OrderFormAction from "@Components/OrderFormAction";
import LoadingIcon from "@Components/LoadingIcon";
import getCalendar from "../../../../utils/getCalendar";
import { withRouter } from "react-router";
import ROUTE from "../../../../utils/routes";
import { checkFieldPattern, checkFieldValid } from "../../../../utils/validate";
import KensaTooltip from "@Components/KensaTooltip";
window.jQuery = window.$ = $;
class ExpertiseAppointmentPage extends Component {
  constructor(props) {
    super(props);
    const { appointmentTime } = this.props.order;
    this.modal = createRef();
    this.checkFieldValid = checkFieldValid.bind(this);
    this.checkFieldPattern = checkFieldPattern.bind(this);
    this.state = {
      isGettingTime: false,
      dates: [],
      tempDateTime: appointmentTime,
      errorAppointmentTime: "",
      errorAppointmentLocation: ""
    };
    this.updateOrder = this.props.updateOrder;
    const {
      vehicleManufacture,
      vehicleType,
      servicePrice,
      areas,
      vehicleName,
    } = this.props.order;
    if (
      !vehicleManufacture ||
      !vehicleType ||
      !servicePrice ||
      !areas ||
      !vehicleName
    ) {
      this.props.history.push(ROUTE.ORDER_EXPERTISE_VEHICLE);
    }
  }
  componentDidMount() {
    if ($('.modal-backdrop')) {
      $('.modal-backdrop').remove();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      const { errorAppointmentTime, errorAppointmentLocation } = this.state;
      if (errorAppointmentLocation || errorAppointmentTime) {
        const { appointmentTime, appointmentLocation } = this.props.order;
        if (errorAppointmentTime) {
          this.checkFieldValid('errorAppointmentTime', appointmentTime, "Mời chọn thời gian gặp mặt")
        }
        if (errorAppointmentLocation) {
          this.checkFieldValid('errorAppointmentLocation', appointmentLocation, "Mời nhập địa chỉ gặp mặt")
        }
      }
    }
  }
  prev() {
    this.props.history.push(ROUTE.ORDER_EXPERTISE_VEHICLE);
  }
  openModal() {
    $(this.modal.current).modal("show");
    this.setState({
      isGettingTime: true,
    });
    const { vehicleType } = this.props.order;
    getCalendar(vehicleType)
      .then((res) => {
        this.setState({
          dates: res.data.data,
          isGettingTime: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onSelectTime(e) {
    const ele = e.target;
    if (
      !$(ele).hasClass(styles.calendarTime_Disabled) &&
      !$(ele).hasClass(styles.calendarTime_Active)
    ) {
      this.setState({
        tempDateTime: ele.getAttribute("data-time"),
      });
    }
  }

  onTextChange(e) {
    this.updateOrder({
      [`${e.target.name}`]: e.target.value,
    });
  }

  checkFormValid() {
    const { appointmentTime, appointmentLocation } = this.props.order;
    let hasError = false;
    if (!this.checkFieldValid('errorAppointmentTime', appointmentTime, "Mời chọn thời gian gặp mặt")) hasError = true;
    if (!this.checkFieldValid('errorAppointmentLocation', appointmentLocation, "Mời nhập địa chỉ gặp mặt")) hasError = true;
    return !hasError;
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.checkFormValid()) {
      return false;
    }
    this.props.history.push(ROUTE.ORDER_EXPERTISE_CUSTOMER);
    return false;
  }

  confirmappointmentTime() {
    const { tempDateTime } = this.state;
    this.updateOrder({
      appointmentTime: tempDateTime,
    });
    $(this.modal.current).modal("hide");
  }

  renderHours(times, index) {
    const { tempDateTime } = this.state;
    return times.map((time, i) => {
      return (
        <div className={styles.calendarCol} key={`time_col_${index}_${i}`}>
          <div
            className={`${styles.calendarTime} ${(!tempDateTime &&
                $("#appointment_time").val() === time.datetime) ||
                (!time.disabled && tempDateTime === time.datetime)
                ? styles.calendarTime_Active
                : ""
              } ${time.inScheduleTechnicians.length > 0
                ? styles.calendarTime_Assigned
                : ""
              } ${time.disabled ? styles.calendarTime_Disabled : ""}`}
            data-time={time.datetime}
            data-toggle={time.inScheduleTechnicians.length > 0 ? "tooltip" : ""}
            title={
              time.inScheduleTechnicians.length > 0
                ? `Nhân viên bận: ${time.inScheduleTechnicians
                  .map((u) => u.name)
                  .join(", ")}`
                : ""
            }
            onClick={(e) => this.onSelectTime(e)}
          >
            {time.time}
          </div>
        </div>
      );
    });
  }

  renderTabPanes(dates) {
    return dates.map((d, i) => (
      <div
        className={`tab-pane fade ${i === 0 ? "show active" : ""}`}
        key={`date_pane_${i}`}
        id={`date_pane_${i}`}
      >
        <div className={styles.calendarContainer}>
          {this.renderHours(d.times)}
        </div>
      </div>
    ));
  }

  renderTabLinks(dates) {
    return dates.map((d, i) => (
      <li className="nav-item" key={`date_link_${i}`}>
        <a
          className={`nav-link ${i === 0 ? "active" : ""}`}
          data-toggle="tab"
          href={`#date_pane_${i}`}
        >
          {dates[i].name}
        </a>
      </li>
    ));
  }
  render() {
    const { isGettingTime, dates, errorAppointmentLocation, errorAppointmentTime } = this.state;
    const { appointmentTime, appointmentLocation, error } = this.props.order;
    return (
      <>
        <form
          className="kensaForm_Container"
          onSubmit={(e) => this.onSubmit(e)}
        >
          <div className="kensaForm_Content">
            <h1 className={`kensaForm_Title`}>Thời gian gặp mặt <KensaTooltip dataKey="thoi_gian_gap_mat" /></h1>
            <div className="expertise-form">
              <div className="row form-group">
                <label className="col-12 d-none d-lg-block col-lg-4 col-form-label">
                  Chọn thời gian: 
                </label>
                <div className="col-12 col-lg-8">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.openModal.bind(this)}
                  >
                    {!appointmentTime ? "Chọn thời gian" : appointmentTime}
                  </button>
                  {errorAppointmentTime && <div className="invalid-feedback">{errorAppointmentTime}</div>}
                </div>
              </div>
              <div className="row form-group">
                <label className="col-12 col-lg-4 col-form-label">Địa chỉ xem xe: <KensaTooltip dataKey="dia_chi_xem_xe" /></label>
                <div className="col-12 col-lg-8">
                  <input
                    type="text"
                    className={`form-control ${errorAppointmentLocation ? 'invalid' : ''}`}
                    name="appointmentLocation"
                    placeholder="Ví dụ: Số 20 đường Cách Mạng Tháng 8, Phường 15, Q. Tân Bình"
                    defaultValue={appointmentLocation}
                    onChange={(e) => this.onTextChange(e)}
                  />
                  {errorAppointmentLocation && <div className="invalid-feedback">{errorAppointmentLocation}</div>}
                </div>
              </div>
              {error && (
                <div className="row">
                  <div className="col-12 text-center">{error}</div>
                </div>
              )}
            </div>
          </div>
          <OrderFormAction
            prev={this.prev.bind(this)}
            submitText="Đặt lịch"
          ></OrderFormAction>
        </form>
        <div className="modal fade" ref={this.modal} id="modalCalendar">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Chọn thời gian</h3>
              </div>
              <div className="modal-body">
                <div id="calendarContainer">
                  {isGettingTime ? (
                    <LoadingIcon />
                  ) : (
                    <>
                      <ul className="nav nav-tabs">
                        {this.renderTabLinks(dates)}
                      </ul>
                      <div className="tab-content">
                        {this.renderTabPanes(dates)}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-12 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm mr-2"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Đóng
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      onClick={this.confirmappointmentTime.bind(this)}
                    >
                      Xác nhận
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ExpertiseAppointmentPage);
