import $ from "jquery";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import React, { Component, createRef } from "react";
import { withRouter } from "react-router";
import axios from "../../utils/axios";
import LoadingIcon from "../LoadingIcon";
import OrderCancellationPolicy from "../OrderCancellationPolicy";
import OrderExpertisePrepayDetail from "../OrderExpertisePrepayDetail";
import OrderExpertisePrice from "../OrderExpertisePrice";
import TechnicianSlider from "../TechnicianSlider";
import ConfirmInfo from "./components/ConfirmInfo";
import styles from "./index.module.scss";

class OrderExpertiseConfirm extends Component {
  constructor(props) {
    super(props);
    this.modal = createRef();
    this.submitOrder = this.props.submitOrder;
    this.modalClose = this.props.modalClose;
    this.updateOrder = this.props.updateOrder;
    this.state = {
      loading: false,
      dataVehicleTypes: [],
      dataAreas: [],
      dataVehicleManufactures: [],
      dataServicePrices: [],
      checkedWarranty: false,
      checkedCancel: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    $(this.modal.current).modal("show");
    $(this.modal.current).on("hidden.bs.modal", () => {
      $(".modal-backdrop").remove();
      this.modalClose();
    });
    Promise.all([this.getVehicleTypes(), this.getVehicleManufactures(), this.getAreas(), this.getServicePrices()]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  onConfirm() {
    $(this.modal.current).modal("hide");
    this.submitOrder();
  }

  getVehicleTypes() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/types")
        .then((res) => {
          this.setState({
            dataVehicleTypes: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getVehicleManufactures() {
    return new Promise((resovle, reject) => {
      axios
        .get("/vehicles/manufactures")
        .then((res) => {
          this.setState({
            dataVehicleManufactures: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getAreas() {
    return new Promise((resovle, reject) => {
      axios
        .get("/areas")
        .then((res) => {
          this.setState({
            dataAreas: res.data.data,
          });
        })
        .catch((err) => {
          console.error(err.response);
        })
        .finally(() => {
          resovle(true);
        });
    });
  }

  getServicePrices() {
    const { vehicleType } = this.props.order;
    if (vehicleType) {
      this.setState({
        loadingPrice: true,
      });

      return new Promise((resovle, reject) => {
        axios
          .get("/services/prices", {
            params: {
              vehicle_type_id: vehicleType,
              service_code: "THAM_DINH_XE",
            },
          })
          .then((res) => {
            this.setState({
              dataServicePrices: res.data.data,
            });
          })
          .catch((err) => {
            console.error(err.response);
          })
          .finally(() => {
            this.setState({
              loadingPrice: false,
            });
            resovle(true);
          });
      });
    } else {
      return Promise.resolve(true);
    }
  }

  vehicleType() {
    const { vehicleType } = this.props.order;
    const { dataVehicleTypes } = this.state;
    if (dataVehicleTypes.filter((vt) => vt.id === vehicleType).length > 0) {
      return dataVehicleTypes.filter((vt) => vt.id === vehicleType)[0].name;
    }
    return 0;
  }

  vehicleManufacture() {
    const { vehicleManufacture } = this.props.order;
    const { dataVehicleManufactures } = this.state;
    if (dataVehicleManufactures.filter((vm) => vm.id === vehicleManufacture).length > 0) {
      return dataVehicleManufactures.filter((vm) => vm.id === vehicleManufacture)[0].name;
    }
    return 0;
  }

  areas() {
    const { areas } = this.props.order;
    const { dataAreas } = this.state;
    if (dataAreas.filter((city) => city.children.filter((d) => areas.indexOf(d.id) > -1).length > 0).length > 0) {
      const selectedCity = dataAreas.filter((city) => city.children.filter((d) => areas.indexOf(d.id) > -1).length > 0);
      return selectedCity
        .map((city) => {
          return city.children
            .filter((d) => areas.indexOf(d.id) > -1)
            .map((d) => d.name)
            .join(", ");
        })
        .join(", ");
    }
    return "";
  }

  areaSubFee() {
    const { areas } = this.props.order;
    const { dataAreas } = this.state;
    if (dataAreas.filter((city) => city.children.filter((d) => areas.indexOf(d.id) > -1).length > 0).length > 0) {
      const selectedCity = dataAreas.filter((city) => city.children.filter((d) => areas.indexOf(d.id) > -1).length > 0);
      return selectedCity.map((city) => {
        return city.children.filter((d) => areas.indexOf(d.id) > -1 && d.id === areas[0])[0].subFee;
      })[0];
    }
    return 0;
  }

  servicePrice() {
    const { servicePrice } = this.props.order;
    const { dataServicePrices } = this.state;
    if (dataServicePrices.filter((sp) => sp.id === servicePrice).length > 0) {
      return dataServicePrices.filter((sp) => sp.id === servicePrice)[0].price;
    }
    return 0;
  }

  onCheckboxChange(e, name) {
    this.setState({
      [`${name}`]: e.target.checked,
    });
  }

  discountFee() {
    const serviceFee = this.servicePrice();
    const { discountType, discountValue } = this.props.order;
    if (discountType === 1) {
      return serviceFee * (discountValue / 100);
    } else {
      return discountValue;
    }
  }

  render() {
    const { loading, checkedWarranty, checkedCancel } = this.state;
    const { appointmentLocation, appointmentTime, note, customerPhone, customerName, vehicleName } = this.props.order;
    return (
      <div className={`modal fade ${styles.kensaConfirm_Modal}`} id="confirmModal" ref={this.modal}>
        <div className={`modal-dialog ${styles.kensaConfirm_ModalDialog}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Xác nhận đơn hàng</h3>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              {loading ? (
                <LoadingIcon />
              ) : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="mb-3 font-weight-bold">Thông tin lịch hẹn</h5>
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Tên khách hàng" value={customerName} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Số điện thoại" value={customerPhone} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Tên dòng xe" value={`${this.vehicleManufacture()} - ${vehicleName}`} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Phân loại xe" value={this.vehicleType()} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Dịch vụ" value="Thẩm định xe" />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Phí dịch vụ" value={this.servicePrice().toLocaleString()} VNĐ />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Thời gian gặp mặt" value={`${appointmentTime} - ${appointmentLocation}`} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Khu vực xem xe" value={this.areas()} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Phụ phí di chuyển" value={`${this.areaSubFee().toLocaleString()} VNĐ`} />
                    </div>
                    <div className="col-12 col-md-6">
                      <ConfirmInfo label="Ghi chú" value={note ? note : "Không"} />
                    </div>
                    <div className="col-12">
                      <h5 className="mb-5 font-weight-bold">Đội ngũ kỹ thuật viên Kensa</h5>
                      <TechnicianSlider />
                    </div>
                    <div className="col-12">
                      <h5 className="mb-3 font-weight-bold">Bảng giá</h5>
                      <div className="row">
                        <div className="col-12 col-lg-8">
                          <div className={styles.kensaConfirm_PriceContainer}>
                            <OrderExpertisePrice order={this.props.order} updateOrder={this.updateOrder} />
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className={styles.kensaConfirm_PrepayContainer}>
                            <OrderExpertisePrepayDetail price={this.servicePrice()} subFee={this.areaSubFee()} discountFee={this.discountFee()} updateOrder={this.updateOrder} otherFee={0} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <h5 className="mb-3 font-weight-bold">Điều khoản bảo hành</h5>
                      <div className={styles.kensaConfirm_Term}>
                        <p className="mb-2">Bảo hành máy móc - Hộp số 3000km hoặc 90 ngày, tùy điều kiện nào đến trước. Áp dụng cho chiếc xe chúng tôi đã thẩm định đạt chuẩn động cơ – hộp số nguyên bản.</p>
                        <p>
                          Chi tiết xem{" "}
                          <a href="https://kiemtraxecu.com/cam-ket-bao-hanh" target="_blank" rel="noreferrer">
                            tại đây
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <h5 className="mb-3 font-weight-bold">Chính sách hủy đơn hàng</h5>
                      <div className="table-responsive">
                        <OrderCancellationPolicy />
                      </div>
                    </div>
                    <label className="col-12 col-form-label text-danger font-weight-bold">
                      <Checkbox className="mr-2" defaultChecked={checkedWarranty} onChange={(e) => this.onCheckboxChange(e, "checkedWarranty")} />
                      Tôi đã hiểu & đồng ý với chính sách Bảo Hành của KENSA
                    </label>
                    <label className="col-12 col-form-label text-danger font-weight-bold">
                      <Checkbox className="mr-2" defaultChecked={checkedCancel} onChange={(e) => this.onCheckboxChange(e, "checkedCancel")} />
                      Tôi đã hiểu & đồng ý với chính sách Hủy Đơn Hàng của KENSA
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary text-uppercase" data-dismiss="modal">
                Quay lại
              </button>
              <button type="button" className="btn btn-primary text-uppercase ml-auto" disabled={loading || !checkedWarranty || !checkedCancel} onClick={this.onConfirm.bind(this)}>
                Xác nhận đăng ký dịch vụ
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(OrderExpertiseConfirm);
