const TOOLTIP_DATA = {
    'phi_dich_vu': {
        content: `Chi phí để thẩm định xe, theo quy trình chuẩn 6 bước KENSA JAPAN. \nBao gồm: \n1-Động Cơ nguyên bản - không ngập nước; \n2-Khung sườn nguyên bản, không tai nạn; \n3-Giấy tờ chính chủ, \n4-Không báo lỗi trực quan; \n5-Chạy thử xe; \n6- Định giá xe. Chi tiết xem <a href="https://kiemtraxecu.com/quy-trinh-kiem-tra-xe-may-cu-co-video-hinh-anh">tại đây</a>`
    },
    'license_plate': {
        content: "Có thể nhập nhiều biển số"
    },
    "phu_phi_di_chuyen": {
        content: "Phụ phí di chuyển nếu quý khách xem xe ở khu vực ngoại thành."
    },
    "thoi_gian_gap_mat": {
        content: "Nhân viên kỹ thuật sẽ có mặt vào đúng thời gian này để xem xe. "
    },
    "dia_chi_xem_xe": {
        content: "Để khách quan, quý khách có thể hẹn gặp nhân viên kỹ thuật ở GẦN địa điểm xem xe. Thông thường, chúng tôi sẽ trao đổi về cách thức làm việc với quý khách trước khi xem xe 10 phút."
    },
    "tien_coc": {
        content: "Để đảm bảo việc sắp xếp nhân viên hỗ trợ quý khách đúng giờ, KENSA cần Quý khách đặt cọc trước. "
    },
    "khu_vuc": {
        content: "Có thể kết hợp đi xem xe ở nhiều khu vực khác nhau. Phụ phí di chuyển có thể áp dụng ở các Quận ngoại thành.",
    },
    "so_luong_xe_can_kiem_tra": {
        content: "KENSA kiểm tra tất cả các xe trong 1 lần đi xem. Quý khách vui lòng hẹn với nhiều người bán để tiện cho việc kiếm tra 1 lượt nhé."
    },
    "thanh_toan_cho_tho": {
        content: "Sau khi kiểm tra xe xong, quý khách có thể thanh toán tiền còn lại cho thợ, hoặc chuyển khoản cho công ty KENSA"
    },
    "yeu_cau_thanh_toan": {
        content: "Lưu ý: Đơn hàng của quý khách sẽ Tự động hủy sau thời gian này, hoặc khi có Khách hàng khác đặt cọc trước. "
    },
    "yeu_cau_dat_coc" : {
        content: "Để đảm bảo việc sắp xếp nhân viên hỗ trợ quý khách đúng giờ, KENSA cần Quý khách đặt cọc trước. "
    }
};

export {
    TOOLTIP_DATA
}