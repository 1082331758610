import { Component } from "react";
import { withRouter } from "react-router";
import styles from "./index.module.scss";
import bodyStyles from "@Layouts/Body/index.module.scss";
import axios from "../../utils/axios";
import LoadingIcon from "@Components/LoadingIcon";
import OrderFindBikeComplete from "@Components/OrderFindBikeComplete";
import OrderExpertiseComplete from "@Components/OrderExpertiseComplete";

class OrderConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      errorPaymentMethod: "",
      errorComission: "",
      errorBuy: "",
      errorPurchaseReason: "",
      success: false,
      loading: true,
      code: props.match.params.code,
      secret: new URLSearchParams(props.location.search).get("secret"),
      paymentMethod: null,
      buy: null,
      commission: null,
      purchaseReason: "",
    };
  }

  componentDidMount() {
    document.title = "Hoàn thành đơn hàng";
    this.getOrderByToken().then((res) => {
      this.setState({
        loading: false,
      });
    });
  }

  getOrderByToken() {
    return new Promise((resolve, reject) => {
      const { code, secret } = this.state;
      axios
        .get(`/orders/by-token?code=${code}&confirm_token=${secret}`)
        .then((res) => {
          const order = res.data.data;
          this.setState({
            order,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }

  render() {
    const { error, loading, code, secret, order } = this.state;
    return (
      <>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="col-12 p-0">
              <h1
                className={`${bodyStyles.kensaBody_Title} ${styles.kensaOrder_Title}`}
              >
                Cập nhật trạng thái đơn hàng {code}
              </h1>
            </div>
            {error && (
              <>
                <div className={bodyStyles.kensaBody_Full}>
                  <div className={styles.kensaOrder_Detail}>
                    <div className="text-center text-danger font-weight-bold py-3">
                      {error instanceof Object
                        ? Object.keys(error)
                            .map((key) => error[key].join(". \n"))
                            .join(". \n")
                        : error}
                    </div>
                  </div>
                </div>
              </>
            )}
            {(!error) && (
              <>
                {order.serviceCode === "TIM_XE" ? (
                  <>
                    <OrderFindBikeComplete
                      order={order}
                      code={code}
                      secret={secret}
                    />
                  </>
                ) : (
                  <>
                    <OrderExpertiseComplete
                      order={order}
                      code={code}
                      secret={secret}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(OrderConfirmPage);
