import React, { Component } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import LoadingIcon from "@Components/LoadingIcon";
import OrderCheckOriginConfirm from "@Components/OrderCheckOriginConfirm";
import OrderCheckOriginPrice from "@Components/OrderCheckOriginPrice";
import bodyStyles from "@Layouts/Body/index.module.scss";
import axios from "../../utils/axios";
import ROUTE from "../../utils/routes";
import CheckOriginCustomer from "./pages/CheckOriginCustomer";
import CheckOriginVehicle from "./pages/CheckOriginVehicle";

class CheckOriginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openingConfirm: false,
      licensePlates: [],
      servicePrice: null,
      note: "",
      customerName: "",
      customerPhone: "",
      coupon: "",
      discountType: null,
      discountValue: 0,
      error: "",
    };
  }

  updateOrder(data) {
    this.setState(data);
  }
  confirmOrder() {
    this.setState({
      openingConfirm: true,
    });
  }

  submitOrder() {
    this.setState({
      loading: true,
    });
    const {
      licensePlates,
      servicePrice,
      note,
      customerName,
      customerPhone,
      coupon
    } = this.state;
    axios
      .post("/orders/check-origin", {
        license_plates: licensePlates,
        service_price_id: servicePrice,
        customer_name: customerName,
        customer_phone: customerPhone,
        coupon: coupon.toUpperCase(),
        note: note,
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/order/" + res.data.data.code);
      })
      .catch((err) => {
        this.setState({
          error: err.response ? err.response.data.message : err.message,
          loading: false,
        });
      });
  }

  render() {
    const { loading, openingConfirm } = this.state;
    return (
      <>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="col-12 p-0">
              <h1 className={bodyStyles.kensaBody_Title}>Đăng Ký Dịch Vụ Check Gốc</h1>
            </div>
            <BrowserRouter>
              <div className={bodyStyles.kensaBody_Left}>
                <Switch>
                  <Redirect
                    exact
                    from={ROUTE.ORDER_CHECK_ORIGIN}
                    to={ROUTE.ORDER_CHECK_ORIGIN_VEHICLE}
                  />
                  <Route
                    path={ROUTE.ORDER_CHECK_ORIGIN_VEHICLE}
                    render={() => (
                      <CheckOriginVehicle
                        order={this.state}
                        updateOrder={this.updateOrder.bind(this)}
                      />
                    )}
                  />
                  <Route
                    path={ROUTE.ORDER_CHECK_ORIGIN_CUSTOMER}
                    render={() => (
                      <CheckOriginCustomer
                        order={this.state}
                        updateOrder={this.updateOrder.bind(this)}
                        confirmOrder={this.confirmOrder.bind(this)}
                      />
                    )}
                  />
                </Switch>
              </div>
              <div className={bodyStyles.kensaBody_Right}>
                <OrderCheckOriginPrice showBox={true} order={this.state} updateOrder={this.updateOrder.bind(this)} />
              </div>
            </BrowserRouter>
          </>
        )}
        {openingConfirm && (
          <>
            <OrderCheckOriginConfirm
              order={this.state}
              submitOrder={this.submitOrder.bind(this)}
              modalClose={(() => {
                this.setState({ openingConfirm: false });
              })}
            />
          </>
        )}
      </>
    );
  }
}
export default withRouter(CheckOriginPage);
