import { Component, createRef } from "react";
import styles from "./index.module.scss";
import { MdClose, MdHelpOutline } from "react-icons/md";
import { TOOLTIP_DATA } from "../../utils/tooltips";
export default class KensaTooltip extends Component {
  constructor(props) {
    super(props);
    const data = TOOLTIP_DATA[this.props.dataKey];
    this.state = {
      content: data ? data.content : null,
    };
    this.container = createRef();
    this.toggle = createRef();
  }
  componentDidMount() {}

  onClickToggle() {
    document
      .querySelectorAll(`.${styles.kensaTooltip_Container}`)
      .forEach((ele) =>
        ele.classList.remove(styles.kensaTooltip_Container_Show)
      );
    const container = this.container.current;
    const content = container.querySelector(`.${styles.kensaTooltip_Content}`);
    container.classList.add(styles.kensaTooltip_Container_Show);
    if (window.innerWidth > 768) {
      const { toggleX, toggleY, toggleWidth } = this.togglePosition();
      const containerX = toggleX - 100 + toggleWidth / 2;
      const containerY = toggleY - 15 - content.offsetHeight;
      container.style.top = `${containerY}px`;
      container.style.left = `${containerX}px`;
    }
  }

  onClickClose() {
    const container = this.container.current;
    container.classList.remove(styles.kensaTooltip_Container_Show);
  }
  onClickContainer(){
      if(window.innerWidth < 768) {
        const container = this.container.current;
        container.classList.remove(styles.kensaTooltip_Container_Show);
      }
  }

  togglePosition() {
    const toggle = this.toggle.current;
    const rect = toggle.getBoundingClientRect();
    let toggleX, toggleY, toggleWidth;
    toggleX = rect.left;
    toggleY = rect.top;
    toggleWidth = rect.width;
    return {
      toggleX,
      toggleY,
      toggleWidth,
    };
  }

  render() {
    const { content } = this.state;
    return (
      <>
        {content && (
          <>
            <button
              type="button"
              className={styles.kensaTooltip_Toggle}
              ref={this.toggle}
              onClick={this.onClickToggle.bind(this)}
            >
              <MdHelpOutline size={20} />
            </button>
            <div className={styles.kensaTooltip_Container} ref={this.container} onClick={this.onClickContainer.bind(this)}>
              <div
                className={`animate__animated animate__fadeInUp ${styles.kensaTooltip_Content}`}
                onClick={this.onClickClose.bind(this)}
              >
                <button type="button" className={styles.kensaTooltip_Close}>
                  <MdClose size={16} />
                </button>
                <div className={styles.kensaTooltip_Body} dangerouslySetInnerHTML={{__html: content}}></div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
