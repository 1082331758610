const ConfirmInfo = (props) => {
  return (
    <div className="row form-group">
      <label className="col-form-label col-12 col-md-6 font-weight-bold">
        {props.label}
      </label>
      <label className="col-form-label col-12 col-md-6">{props.value}</label>
    </div>
  );
};

export default ConfirmInfo;
