import { Component } from "react";
import styles from "./index.module.scss";
export default class OrderCancellationPolicy extends Component {
  render() {
    return (
      <>
        <table className={`table ${styles.kensaPolicy_Table}`}>
          <thead>
            <tr>
              <th>Thời gian hủy đơn hàng</th>
              <th>Phí hủy đơn hàng</th>
              <th>Số tiền cọc hoàn trả</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Trong vòng 20 phút sau khi đặt cọc</td>
              <td>0% Tiền cọc</td>
              <td>100% Tiền cọc</td>
            </tr>
            <tr>
              <td>{"> 3 ngày trước ngày hẹn"}</td>
              <td>30% Tiền cọc</td>
              <td>70% Tiền cọc</td>
            </tr>
            <tr>
              <td>{"<= 3 ngày trước ngày hẹn"}</td>
              <td>100% Tiền cọc</td>
              <td>0% Tiền cọc</td>
            </tr>
          </tbody>
        </table>
        <div className="text-danger mb-3" style={{fontStyle: 'italic'}}>
          Tiền cọc sẽ được hoàn lại trong 3-5 ngày làm việc
        </div>
      </>
    );
  }
}
