import React from "react";
import { Redirect, Route } from "react-router-dom";
import useAuth from "src/hooks/use-auth";
import ROUTE from "src/utils/routes";
import LoadingIcon from "../LoadingIcon";

function PrivateRoute({ children, ...rest }) {
  const [isVerifying, user, isLoggedIn] = useAuth();

  if (isVerifying) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Route {...rest} render={() => (isLoggedIn ? children : <Redirect to={ROUTE.TECHNICIAN_LOGIN} />)} />
    </>
  );
}

export default PrivateRoute;
