import { Component } from "react";
import OrderFormAction from "@Components/OrderFormAction";
import { withRouter } from "react-router";
import ROUTE from "../../../../utils/routes";
import { checkFieldValid, checkFieldPattern } from '../../../../utils/validate';

class CheckOriginCustomer extends Component {
  constructor(props) {
    super(props);
    this.updateOrder = this.props.updateOrder;
    this.confirmOrder = this.props.confirmOrder;
    this.checkFieldValid = checkFieldValid.bind(this);
    this.checkFieldPattern = checkFieldPattern.bind(this);

    const {
      licensePlates,
      servicePrice
    } = this.props.order;
    if(!licensePlates.length || !servicePrice) {
      this.props.history.push(ROUTE.ORDER_CHECK_ORIGIN_VEHICLE);
    }
    this.state = {
      errorCustomerPhone: "",
      errorCustomerName: "",
    }
  }

  checkFormValid() {
    const {
      customerName,
      customerPhone,
    } = this.props.order;
    let hasError = false;
    if(this.checkFieldValid('errorCustomerPhone', customerPhone, "Mời nhập số điện thoại của bạn")){
      if(!this.checkFieldPattern('errorCustomerPhone', customerPhone, /(84|0[3|5|7|8|9])+([0-9]{8})\b/g, "Định dạng số điện thoại không hợp lệ")){
        hasError = true;
      }
    } else {
      hasError = true;
    }
    if (!this.checkFieldValid('errorCustomerName', customerName, "Mời nhập tên của bạn")) hasError = true;
    return !hasError;
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      const { errorCustomerPhone, errorCustomerName } = this.state;
      if(errorCustomerName || errorCustomerPhone) {
        this.checkFormValid();
      }
    }
  }

  onTextChange(e) {
    this.updateOrder({
      [`${e.target.name}`]: e.target.value,
    });
  }
  onSubmit(e) {
   e.preventDefault();
    e.stopPropagation();
    if (!this.checkFormValid()) {
      return false;
    }
    
    this.confirmOrder();
    return false;
  }
  prev() {
    this.props.history.push(ROUTE.ORDER_CHECK_ORIGIN_VEHICLE);
  }

  render() {
    const { customerPhone, customerName, note, error } = this.props.order;
    const { errorCustomerName, errorCustomerPhone } = this.state;
    return (
      <form
        className={"kensaForm_Container"}
        onSubmit={(e) => this.onSubmit(e)}
      >
        <div className="kensaForm_Content">
          <h1 className={`kensaForm_Title`}>Thông tin của Quý Khách</h1>
          <form className="expertise-form">
            <div className="row form-group">
              <label className="col-12 col-lg-4 col-form-label">Tên:</label>
              <div className="col-12 col-lg-8">
                <input
                  type="text"
                  className={`form-control ${errorCustomerName ? 'invalid' : ''}`}
                  name="customerName"
                  defaultValue={customerName}
                  placeholder="Ví dụ: Đình Tùng"
                  maxLength="100"
                  onChange={e => this.onTextChange(e)}
                />
                {errorCustomerName && <div className="invalid-feedback">{errorCustomerName}</div>}
              </div>
            </div>
            <div className="row form-group">
              <label className="col-12 col-lg-4 col-form-label">
                Số điện thoại:
              </label>
              <div className="col-12 col-lg-8">
                <input
                  type="text"
                  className={`form-control ${errorCustomerPhone ? 'invalid' : ''}`}
                  name="customerPhone"
                  defaultValue={customerPhone}
                  placeholder="Ví dụ: 0908456852"
                  maxLength="11"
                  onChange={e => this.onTextChange(e)}
                />
                {errorCustomerPhone && (<div className="invalid-feedback">{errorCustomerPhone}</div>)}
              </div>
            </div>
            <div className="row form-group">
              <label className="col-12 col-lg-4 col-form-label">Lưu ý:</label>
              <div className="col-12 col-lg-8">
                <textarea
                  type="text"
                  className="form-control"
                  name="note"
                  rows="3"
                  defaultValue={note}
                  onChange={e => this.onTextChange(e)}
                ></textarea>
              </div>
            </div>
            {error && <div className="row"><div className="col-12 text-center text-danger">{error}</div></div>}
          </form>
        </div>
        <OrderFormAction prev={this.prev.bind(this)} ></OrderFormAction>
      </form>
    );
  }
}
export default withRouter(CheckOriginCustomer)