import { Component } from "react";
import { withRouter } from "react-router";
import axios from "../../utils/axios";
import bodyStyles from "@Layouts/Body/index.module.scss";
import OrderExpertiseDetail from "@Components/OrderExpertiseDetail";
import OrderExpertiseTechnician from "@Components/OrderExpertiseTechnician";
import styles from "./index.module.scss";
import LoadingIcon from "@Components/LoadingIcon";
import OrderPayment from "@Components/OrderPayment";
import { OrderStatus, PaymentStatus } from "../../utils/enums";
import OrderCheckOriginDetail from "@Components/OrderCheckOriginDetail";
import OrderFindBikeDetail from "@Components/OrderFindBikeDetail";
import { Link } from "react-router-dom";
import ROUTE from "../../utils/routes";
import TechnicianSlider from "@Components/TechnicianSlider";
import OrderCancellationPolicy from "@Components/OrderCancellationPolicy";
import React from "react";

const OrderDetail = (props) => {
  const serviceCode = props.serviceCode;
  switch (serviceCode) {
    case "THAM_DINH_XE":
      return <OrderExpertiseDetail {...props} />;
    case "CHECK_GOC":
      return <OrderCheckOriginDetail {...props} />;
    case "TIM_XE":
      return <OrderFindBikeDetail {...props} />;
    default:
      return <span className="text-danger">Đơn hàng không tồn tại.</span>;
  }
};

const OrderDetailFooter = (props) => {
  const { serviceCode, status, reason } = props;
  if (status === OrderStatus.CREATED) {
    // return <OrderPayment {...props} />;
    return (
      <>
        <div className="row ">
          <div className="col-12">
            <p className="font-weight-bold">Đơn hàng của bạn đã được tạo thành công. Nhân viên của chúng tôi sẽ liên hệ lại với bạn sớm nhất có thể.</p>
          </div>
        </div>
      </>
    );
  } else if (status === OrderStatus.COMPLETED) {
    return (
      <>
        <div className="row ">
          <div className="col-12">
            <p className="font-weight-bold">Đơn hàng của bạn đã hoàn thành.</p>
          </div>
        </div>
      </>
    );
  } else if (status === OrderStatus.CANCELED) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <p className="font-weight-bold">Đơn hàng của bạn đã bị hủy với lý do sau: </p>
            <p>{reason}</p>
          </div>
        </div>
      </>
    );
  } else {
    if (serviceCode === "THAM_DINH_XE") {
      return <OrderExpertiseTechnician {...props} />;
    } else {
      return (
        <div className="row form-group">
          <div className="col-12">
            <p>Bạn đã thanh toán thành công cho đơn hàng. Đơn hàng đang được xử lý, xin vui lòng chờ Kensa gửi kết quả qua SDT của bạn.</p>
          </div>
        </div>
      );
    }
  }
};
class OrderDetailPage extends Component {
  constructor(props) {
    super(props);
    const code = this.props.match.params.code;
    this.state = {
      loading: true,
      code: code,
      serviceName: "",
      serviceCode: "",
      serviceId: null,
      orderName: "",
      orderId: null,
      vehicleManufacture: "",
      vehicleType: "",
      servicePrice: "",
      appointmentTime: "",
      appointmentLocation: "",
      areas: "",
      note: "",
      paymentStatus: PaymentStatus.PENDING,
      paymentMethod: 2,
      paymentPrepayRatio: 50,
      loadingPaymentUrl: false,
      paymentMethods: [],
      paymentPrepayRatios: [],
      coupon: "",
    };
  }

  componentDidMount() {
    document.title = "Chi tiết đơn hàng";
    Promise.all([this.getOrder(), this.getPaymentMethods(), this.getPaymentPrepayRatios()]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  getOrder() {
    return new Promise((resolve, reject) => {
      const { code } = this.state;
      axios
        .get("/orders/" + code)
        .then((res) => {
          const order = res.data.data;
          this.setState({
            ...order,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }

  getPaymentMethods() {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/methods")
        .then((res) => {
          const methods = res.data.data;
          this.setState({
            paymentMethods: methods,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }

  getPaymentPrepayRatios() {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments/prepay-ratios")
        .then((res) => {
          const ratios = res.data.data;
          this.setState({
            paymentPrepayRatios: ratios,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.data.message : err.message,
          });
        })
        .finally(() => {
          resolve(true);
        });
    });
  }

  startPayment() {
    this.setState({
      loadingPaymentUrl: true,
    });
    const { code, paymentMethod, paymentPrepayRatio } = this.state;
    axios
      .post(`/payments/${code}`, {
        payment_method_id: paymentMethod,
        payment_prepay_ratio: paymentPrepayRatio,
      })
      .then((res) => {
        if (res.data.data.url) {
          window.location.href = res.data.data.url;
        } else {
          this.props.history.push("/order/payment-return?order_code=" + code);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        this.setState({
          loadingPaymentUrl: false,
        });
      });
  }

  updateState(data) {
    this.setState(data);
  }
  render() {
    const { loading, error, name, paymentMethod, paymentPrepayRatio, loadingPaymentUrl, status, paymentStatus } = this.state;
    return (
      <>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="col-12 p-0">
              <h1 className={bodyStyles.kensaBody_Title}>Xác nhận yêu cầu - {name}</h1>
            </div>

            {error ? (
              <>
                <div className={bodyStyles.kensaBody_Full}>
                  <div className={styles.kensaOrder_Detail}>
                    <div className="text-center text-danger font-weight-bold mb-3">{error}</div>
                    <div className="text-center">
                      <Link className="btn btn-primary" to={ROUTE.ORDER}>
                        Quay về trang chủ
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={bodyStyles.kensaBody_Full}>
                  <div className={styles.kensaOrder_Detail}>
                    <OrderDetailFooter {...this.state} updateState={this.updateState.bind(this)} startPayment={this.startPayment.bind(this)} />
                  </div>
                  <div className={styles.kensaOrder_Footer}>
                    <h5 className="font-weight-bold mb-3">Kỹ thuật viên sẽ được phân công Ngẫu nhiên</h5>
                    <TechnicianSlider />
                  </div>
                  <div className={styles.kensaOrder_Footer}>
                    <h5 className="font-weight-bold mb-3">Chính sách hủy đơn hàng</h5>
                    <OrderCancellationPolicy />
                  </div>
                  <div className={styles.kensaOrder_Detail}>
                    <OrderDetail {...this.state} />
                  </div>
                  {status !== OrderStatus.CONFIRMED && status !== OrderStatus.CANCELED && paymentStatus === PaymentStatus.PENDING && (
                    <div className={styles.kensaOrder_Footer}>
                      <div className="col-12 text-center">
                        <button type="button" className="btn btn-success" disabled={!paymentMethod || !paymentPrepayRatio || loadingPaymentUrl} onClick={this.startPayment.bind(this)} style={{ fontSize: "16px" }}>
                          {paymentMethod === 1 ? <>XÁC NHẬN</> : <>BẮT ĐẦU THANH TOÁN</>}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
export default withRouter(OrderDetailPage);
