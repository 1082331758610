import { Component } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import KensaTooltip from "@Components/KensaTooltip";
import { WithContext as ReactTags } from "react-tag-input";
import LoadingIcon from "@Components/LoadingIcon";
import OrderFormAction from "@Components/OrderFormAction";
import axios from "../../../../utils/axios";
import ROUTE from "../../../../utils/routes";
import { checkFieldValid } from '../../../../utils/validate';
const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class CheckOriginVehicle extends Component {
  constructor(props) {
    super(props);
    this.checkFieldValid = checkFieldValid.bind(this);
    this.updateOrder = this.props.updateOrder;
    this.state = {
      loadingData: false,
      servicePrices: [],
      error: "",
      errorServicePrice: "",
      errorLicensePlates: ""
    };
  }
  componentDidMount() {
      this.getServicePrices();
  }
  componentDidUpdate(prevProps) {
    if(prevProps.order !== this.props.order) {
      if(this.state.errorServicePrice || this.state.errorLicensePlates){
        this.checkFormValid();
      }
    }
  }
  getServicePrices() {
    this.setState({
      loadingData: true,
    });
    axios
      .get("/services/prices", {
        params: {
          service_code: "CHECK_GOC",
        },
      })
      .then((res) => {
          this.setState({
              servicePrices: res.data.data
          });
      })
      .catch((err) => {
        this.setState({
            error: err.response ? err.response.data.message : err.message
        });
      })
      .finally(() => {
        this.setState({
          loadingData: false,
        });
      });
  }

  onSelect(e, name) {
    this.updateOrder({
        [name]: e.value
    });
  }

  checkFormValid() {
    const {
      servicePrice,
      licensePlates,
    } = this.props.order;
    let hasError = false;
    if (!this.checkFieldValid('errorLicensePlates', licensePlates, "Mời nhập biển số cần kiểm tra")) hasError = true;
    if (!this.checkFieldValid('errorServicePrice', servicePrice, "Mời chọn gói giá kiểm tra")) hasError = true;
    return !hasError;
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.checkFormValid()) {
      return false;
    }
    this.props.history.push(ROUTE.ORDER_CHECK_ORIGIN_CUSTOMER);
    return false;
  }
  onDeleteTag(i) {
    const { licensePlates } = this.props.order;
    this.updateOrder({
      licensePlates: licensePlates.filter((lp, index) => index !== i),
    });
  }
  onAdditionTag(tag) {
    const licensePlates = this.props.order.licensePlates;
    licensePlates.push(tag.id);
    this.updateOrder({
      licensePlates: licensePlates,
    });
  }
  onFocusOutTag(tag) {
    const licensePlates = this.props.order.licensePlates;
    if (tag && licensePlates.indexOf(tag) === -1) {
      licensePlates.push(tag);
      this.updateOrder({
        licensePlates: licensePlates,
      });
    }
  }

  onDragTag(tag, currPos, newPos) {
    const tags = this.props.order.licensePlates;
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag.id);

    this.updateOrder({
      licensePlates: newTags,
    });
  }
  validData() {
    const { licensePlates, servicePrice } = this.props.order;
    return licensePlates.length > 0 && servicePrice;
  }
  render() {
    const { servicePrices, loadingData, error, errorLicensePlates, errorServicePrice } = this.state;
    const { licensePlates, servicePrice } = this.props.order;
    const licensePlateTags = licensePlates.map((lp) => {
      return { id: lp, text: lp };
    });
    let selectedServicePrice =
    servicePrices.filter((dv) => dv.id === servicePrice).length > 0
        ? servicePrices.filter((dv) => dv.id === servicePrice)[0]
        : null;
    if (selectedServicePrice) {
      selectedServicePrice = {
        value: selectedServicePrice.id,
        label: selectedServicePrice.name,
      };
    }

    return (
      <>
        {loadingData ? (
          <LoadingIcon />
        ) : (
          <>
            <form
              className="kensaForm_Container"
              onSubmit={e => this.onSubmit(e)}
            >
              <div className="kensaForm_Content">
                <h1 className="kensaForm_Title">Thông tin check gốc</h1>
                <div className="check-origin-form">
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Chọn gói dịch vụ
                    </label>
                    <div className="col-12 col-lg-8">
                      <Select
                        options={servicePrices.map((p) => {
                          return { value: p.id, label: p.name };
                        })}
                        classNamePrefix="kensaSelect"
                        className={errorServicePrice ? 'invalid-select' : ''}
                        defaultValue={selectedServicePrice}
                        onChange={(e) => this.onSelect(e, "servicePrice")}
                        menuPortalTarget={document.querySelector("body")}
                        placeholder="Chọn gói dịch vụ..."
                      />
                      {errorServicePrice && <div className="invalid-feedback">{errorServicePrice}</div>}
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-12 col-lg-4 col-form-label">
                      Biển số xe <KensaTooltip dataKey={"license_plate"} />
                    </label>
                    <div className="col-12 col-lg-8">
                      <ReactTags
                        classNames={{ tagInputField: `form-control ${errorLicensePlates ? "invalid" : ''}` }}
                        placeholder='Biển số cách bởi dấu ","'
                        tags={licensePlateTags}
                        delimiters={delimiters}
                        handleDelete={this.onDeleteTag.bind(this)}
                        handleAddition={this.onAdditionTag.bind(this)}
                        handleDrag={this.onDragTag.bind(this)}
                        handleInputBlur={this.onFocusOutTag.bind(this)}
                      />
                      {errorLicensePlates && <div className="invalid-feedback">{errorLicensePlates}</div>}
                    </div>
                  </div>
                  {error && (
                    <div className="row">
                      <div className="col-12 text-center text-danger">
                        {error}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <OrderFormAction submitText="Đặt dịch vụ"/>
            </form>
          </>
        )}
      </>
    );
  }
}
export default withRouter(CheckOriginVehicle);
